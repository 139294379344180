// vendors
import { useState } from "react";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";

// components
import NodeType from "../../Components/NodeType";
import Icon from "../../Components/Icon";
import UIButton from "../../Components/Button";
import { TabPanel } from "../../Components/Tabs";
import ExcludeDates from "./exclude-dates";
import DateRangeConfig from "./date-range-config";

// styled
import {
  EditConfigurationButton,
  TabsStyled,
  TabStyled,
  SectionStyled,
  PopoverConfig,
} from "./date.styled";

import { FromValue, ToValue, ConfigProps } from "./types";
import { fromInit, toInit } from "./utils";

const Configuration = (props: ConfigProps) => {
  const { node, onSave, selectedDates, excludedDates, onClose } = props;
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [data, updateData] = useState<{
    dateFormat: string;
    from: FromValue;
    to: ToValue;
    dateRange: boolean;
  }>(
    selectedDates || {
      dateFormat: "",
      from: fromInit,
      to: toInit,
      dateRange: false,
    }
  );
  const [excludeDates, updateExcludedDates] = useState<{
    excludeAllDays: string[];
    excludeSpecificDates: string[];
  }>(excludedDates || { excludeAllDays: [], excludeSpecificDates: [] });
  const [error, updateError] = useState<boolean>(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handlePopoverToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    updateData(selectedDates);
    updateExcludedDates(excludedDates);
    onClose && onClose();
  };

  const handleDateChange = ({
    from,
    to,
    error,
    dateFormat,
    dateRange,
  }: any) => {
    if (error) {
      updateError(error);
    } else {
      updateData({ ...data, from, to, dateFormat, dateRange });
      updateError(false);
    }
  };

  const handleSaveClick = () => {
    onSave(data, excludeDates);
    setAnchorEl(null);
  };

  const handleCancelClick = () => {
    handleClose();
  };

  const handleExcludeDateChange = ({
    excludeAllDays,
    excludeSpecificDates,
  }: {
    excludeAllDays: string[];
    excludeSpecificDates: string[];
  }) => {
    updateExcludedDates({
      ...excludeDates,
      excludeAllDays,
      excludeSpecificDates,
    });
  };

  return (
    <>
      <EditConfigurationButton onClick={handlePopoverToggle}>
        <Icon icon="pencil" size={14} color="#7E8392" />
      </EditConfigurationButton>
      <Popover
        className="ignore-onClickOutside"
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={3}
        {...PopoverConfig(open)}
        sx={{
          "& .MuiPopover-paper": {
            macHeight: "calc(100vh - 192px)",
            minWidth: "320px",
            maxWidth: "400px",
          },
        }}
      >
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
          }}
        >
          <NodeType text={"Configuration"} color={node.color} icon={node.icon} />
        </Box>

        <SectionStyled>
          <TabsStyled
            value={activeTab}
            onChange={handleChange}
            scrollButtons={false}
          >
            <TabStyled label="Date Selection" />
            <TabStyled label="Exclude Dates" />
          </TabsStyled>
          <Box
            sx={{
              maxHeight: "calc(100vh - 405px)",
              overflowY: "auto",
              paddingRight: "6px",
              marginTop: "8px",
            }}
          >
            <TabPanel value={activeTab} index={0}>
              <DateRangeConfig
                onChange={handleDateChange}
                selectedDates={data}
              />
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <ExcludeDates
                onChange={handleExcludeDateChange}
                excludeDates={excludeDates}
              />
            </TabPanel>
          </Box>
        </SectionStyled>
        <SectionStyled>
          <Box display={"flex"} gap={"20px"} justifyContent={"flex-end"}>
            <UIButton variant="outlined" onClick={handleCancelClick}>
              Cancel
            </UIButton>
            <UIButton
              variant="contained"
              disabled={error}
              onClick={handleSaveClick}
            >
              Save
            </UIButton>
          </Box>
        </SectionStyled>
      </Popover>
    </>
  );
};

export default Configuration;
