// vendors
import React from "react";
import Checkbox from "@mui/material/Checkbox";
import ListItemIcon from "@mui/material/ListItemIcon";
import Chip from "@mui/material/Chip";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import { SelectChangeEvent } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";

// utils
import { MenuProps, options } from "./utils";

// components
import Icon from "../../Components/Icon";

// styles
import {
  ListItemTextStyled,
  MultiSelectWrapperStyled,
  DropdownLabelStyled,
  PlaceholderTextStyled,
} from "./date.styled";

interface Props {
  onChange: (event: SelectChangeEvent<string[]>) => void;
  onDelete: (event: React.MouseEvent<HTMLElement>, value: string) => void;
  selectedList: string[];
  selectAll?: boolean;
}

const MultiSelect = (props: Props) => {
  const { onChange, selectedList, onDelete, selectAll = false } = props;
  const isAllSelected =
    options.length > 0 && selectedList.length === options.length;

  return (
    <MultiSelectWrapperStyled>
      <DropdownLabelStyled id="mutiple-select-label">
        Exclude All-Days
      </DropdownLabelStyled>
      <Select
        labelId="mutiple-select-label"
        multiple
        displayEmpty
        IconComponent={KeyboardArrowDownIcon}
        value={selectedList}
        input={
          <OutlinedInput sx={{ backgroundColor: "#F5F6F8" }} size="small" />
        }
        onChange={onChange}
        renderValue={(selected: any) => {
          if (selected.length === 0) {
            return (
              <PlaceholderTextStyled>
                Select the day to be excluded
              </PlaceholderTextStyled>
            );
          }

          return (selected as string[]).map((value) => (
            <Chip
              size="small"
              key={value}
              label={value.substring(0, 3)}
              clickable
              onDelete={(e) => onDelete(e, value)}
              deleteIcon={
                <CloseIcon onMouseDown={(event) => event.stopPropagation()} />
              }
              sx={{
                borderRadius: "2px",
                backgroundColor: "#fff",
                padding: "0 !important",
              }}
            />
          ));
        }}
        fullWidth
        // @ts-ignore
        MenuProps={MenuProps}
        sx={{
          "& .MuiList-root": {
            display: "grid",
            gridTemplateColumns: "auto auto auto",
          },
        }}
      >
        {selectAll && (
          <MenuItem value="all">
            <ListItemIcon>
              <Checkbox
                checked={isAllSelected}
                indeterminate={
                  selectedList.length > 0 &&
                  selectedList.length < options.length
                }
                checkedIcon={<Icon icon="checked" size={16} />}
                icon={<Icon icon="checkbox-default" size={16} />}
              />
            </ListItemIcon>
            <ListItemTextStyled primary="Select All" />
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem
            key={option}
            value={option}
            sx={{  py: '4px', px: 0 }}
          >
            <ListItemIcon
              sx={{
                minWidth: "unset",
              }}
            >
              <Checkbox
                checked={selectedList.indexOf(option) > -1}
                checkedIcon={<Icon icon="checked" size={16} />}
                icon={<Icon icon="checkbox-default" size={16} />}
              />
            </ListItemIcon>
            <ListItemTextStyled primary={option} />
          </MenuItem>
        ))}
      </Select>
    </MultiSelectWrapperStyled>
  );
};

export default MultiSelect;
