// vendors
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

// components
import { NodeType, Dialog, UIButton, Icon, Input } from "../../ui-components";
import { isApiVariable } from "../../../../Modules/FlowBuilder/Utils/editor";

type Props = {
  url: string;
  open: boolean;
  onClose: () => void;
  onSave: (imgURL: string) => void;
};

const ImgModal = (props: Props) => {
  const [imgURL, setImgURL] = useState<string>(props.url || "");

  const validateImgURL = (url: string) => {
    const isVariable =  isApiVariable(url)
    if(isVariable){
      return isVariable
    }
    const regex = /^https?:\/\/.*\/.*\.(png|gif|webp|jpeg|jpg)\??.*$/gim;
    return regex.test(url);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImgURL(event.target.value);
  };

  useEffect(() => {
    setImgURL(props.url);
  }, [props.url]);

  return (
    <Dialog
      className="ignore-onClickOutside"
      open={props.open}
      onClose={props.onClose}
      sx={{ minWidth: "420px" }}
    >
      <Dialog.Header
        component="div"
        sx={{ padding: 0, borderBottom: "1px solid #F5F6F8" }}
      >
        <Stack direction="row" justifyContent="space-between">
          <NodeType color="#84CBFF" text="Image URL" icon="image" />
          <Box
            sx={{
              px: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton onClick={props.onClose}>
              <Icon icon="close" size={16} />
            </IconButton>
          </Box>
        </Stack>
      </Dialog.Header>
      <Dialog.Body sx={{ px: "16px", py: "8px !important" }}>
        <Typography
          component="label"
          sx={{
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "18px",
            color: "#7E8392",
            pb: "4px",
          }}
        >
          URL
        </Typography>
        <Input
          placeholder="link"
          id="image-link-input"
          size="small"
          name="url"
          onChange={onChange}
          value={imgURL}
          error={imgURL !== "" && !validateImgURL(imgURL)}
          fullWidth
        />
      </Dialog.Body>
      <Dialog.Footer
        sx={{
          justifyContent: "space-between",
          px: "16px",
          pb: "8px",
          borderTop: "1px solid #F5F6F8",
        }}
      >
        <UIButton variant="outlined" onClick={props.onClose}>
          Cancel
        </UIButton>
        <UIButton
          disabled={!imgURL || !validateImgURL(imgURL)}
          variant="contained"
          startIcon={<Icon icon="save" size={12} />}
          onClick={() => props.onSave(imgURL)}
        >
          Save
        </UIButton>
      </Dialog.Footer>
    </Dialog>
  );
};
export default ImgModal;
