import { AxiosResponse } from "axios";

import { conversationAxios } from "../../../Utils";
import { getHttpService } from "../../HttpService";
import { FLOW_BUILDER_ENDPOINTS } from "../Endpoints";
import { bot_id } from "../../../Store/Slices/DashboardAnalytics/dashboardanalytics.selector";

export class FlowBuilderService {
  static httpService = getHttpService(conversationAxios);

  static getFlowById = (id = "", config = {}): Promise<AxiosResponse<any>> => {
    return FlowBuilderService.httpService.get({
      url: FLOW_BUILDER_ENDPOINTS.GET_FLOW,
      headers: config,
      pathParams: [id],
    });
  };

  //copy flow
  static copyFlow = (id = "", bot_id = ""): Promise<AxiosResponse<any>> => {
    console.log("Bot is is=====", bot_id)
    const appid = {
      botid: bot_id,
      channel: "website",
    };
    return FlowBuilderService.httpService.put({
      url: FLOW_BUILDER_ENDPOINTS.CLONE_FLOW,
      headers: {
        // ...config,
        appid: JSON.stringify(appid),
      },
      pathParams: [id],
    });
  };

  static getFlows = (
    queryData = {},
    config = {}
  ): Promise<AxiosResponse<any>> => {
    return FlowBuilderService.httpService.get({
      url: FLOW_BUILDER_ENDPOINTS.GET_FLOWS,
      params: queryData,
      headers: config,
    });
  };

  static createFlow = (data = {}, config = {}): Promise<AxiosResponse<any>> => {
    return FlowBuilderService.httpService.post({
      url: FLOW_BUILDER_ENDPOINTS.CREATE_FLOW,
      data,
      headers: config,
    });
  };

  static updateFlow = (
    id = "",
    queryData = {},
    config = {}
  ): Promise<AxiosResponse<any>> => {
    return FlowBuilderService.httpService.put({
      url: FLOW_BUILDER_ENDPOINTS.UPDATE_FLOW,
      data: queryData,
      headers: config,
      pathParams: [id],
    });
  };

  static deleteFlow = (
    id = "",
    queryData = {},
    config = {}
  ): Promise<AxiosResponse<any>> => {
    return FlowBuilderService.httpService.httpDelete({
      url: FLOW_BUILDER_ENDPOINTS.DELETE_FLOW,
      data: queryData,
      headers: config,
      pathParams: [id],
    });
  };

  static createNode = (data = {}, config = {}): Promise<AxiosResponse<any>> => {
    return FlowBuilderService.httpService.post({
      url: FLOW_BUILDER_ENDPOINTS.CREATE_NODE,
      data,
      headers: config,
    });
  };

  static updateNode = (data = {}, config = {}): Promise<AxiosResponse<any>> => {
    return FlowBuilderService.httpService.put({
      url: FLOW_BUILDER_ENDPOINTS.UPDATE_NODE,
      data,
      headers: config,
    });
  };

  static deleteNode = (data = {}, config = {}): Promise<AxiosResponse<any>> => {
    return FlowBuilderService.httpService.httpDelete({
      url: FLOW_BUILDER_ENDPOINTS.UPDATE_NODE,
      data,
      headers: config,
    });
  };

  static updateNodeStage = (
    data = {},
    config = {}
  ): Promise<AxiosResponse<any>> => {
    return FlowBuilderService.httpService.post({
      url: FLOW_BUILDER_ENDPOINTS.UPDATE_NODE_STAGE,
      data,
      headers: config,
    });
  };
  static getWhatsappUserConsent = (
    queryData = {},
    config = {}
  ): Promise<AxiosResponse<any>> => {
    return FlowBuilderService.httpService.get({
      url: FLOW_BUILDER_ENDPOINTS.WHATSAPP_USER_CONSENT,
      params: queryData,
      headers: config,
    });
  };
  static updateWhatsappUserConsent = (
    data = {},
    config = {}
  ): Promise<AxiosResponse<any>> => {
    return FlowBuilderService.httpService.post({
      url: FLOW_BUILDER_ENDPOINTS.WHATSAPP_USER_CONSENT,
      data,
      headers: config,
    });
  };

  static async getContactPropertyList(): Promise<AxiosResponse<any>> {
    return await FlowBuilderService.httpService.get({
      url: FLOW_BUILDER_ENDPOINTS.CONTACT_PROPERTIES,
    });
  }

  static getApiList = (): Promise<AxiosResponse<any>> => {
    return FlowBuilderService.httpService.get({
      url: FLOW_BUILDER_ENDPOINTS.API_OPTIONS,
    });
  };
}
