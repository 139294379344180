// vendors
import { EditorState, ContentState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import store from "../../../Store/index";
const getInitialEditiorText = (text: string) => {
  const currentBot = store.getState();
  if (
    currentBot?.userData?.dashboardData?.currentBot?.channel === "whatsapp" ||
    currentBot?.userData?.dashboardData?.currentBot?.channel === "messenger" ||
    currentBot?.userData?.dashboardData?.currentBot?.channel === "telegram"
  ) {
    text = getHtmlTextChannelWise(text);
  }
  const contentBlock = htmlToDraft(text);
  let initialEditorState = EditorState.createEmpty();
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks,
    );
    const editorState = EditorState.createWithContent(contentState);
    initialEditorState = editorState;
  }
  return initialEditorState;
};

const getPlainTextData = (editorState: EditorState) => {
  if (!editorState) return;
  const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
  const value = blocks
    .map((block) => (!block.text.trim() && "\n") || block.text)
    .join("\n");

  return value !== "\n" ? value : "";
};

const getHTMLContent = (editorState: EditorState) => {
  return draftToHtml(convertToRaw(editorState.getCurrentContent()));
};

const hasIgnoredClass = (element: any, ignoredClass: any) =>
  (element.correspondingElement
    ? element.correspondingElement
    : element
  ).classList.contains(ignoredClass);

const isInIgnoredElement = (element: any, ignoredClass: any) => {
  do {
    if (
      hasIgnoredClass(element, ignoredClass) ||
      hasIgnoredClass(element, "MuiPopover-root") ||
      hasIgnoredClass(element, "MuiPickersPopper-root")
    ) {
      return true;
    }
  } while ((element = element.parentElement));

  return false;
};

const getMediaDetails = (response: any) => {
  let mediaDetails: any = {};
  if (response && response !== null && response.length > 0) {
    const match = response.filter((item: any) => item.type === "text");
    mediaDetails = match.length === 1 ? match?.[0]?.media : "";
  }
  return mediaDetails;
};

const getMediaDetailsForMediaNode = (response: any) => {
  let mediaDetails: any= {};  
  if (response?.[0]?.value) {
    mediaDetails = response[0]
  }
  return mediaDetails;
};

const clean = (object: any) => {
  if (object) {
    Object.keys(object).forEach(function (key) {
      if (object[key].url === "") {
        delete object[key];
      }
    });
    return object;
  }
};

const cleanMediaDetails = (mediaDetails: any) => {
  return clean(mediaDetails);
};

const htmlStringToLink = (htmlString: string) => {
  // Regular expression to match URLs
  const regex = /(https?:\/\/[^<>\s]+)/g;
  const parts = htmlString?.split(regex);

  if (!parts) return htmlString;

  return parts
    ?.map((part: any, index: number) => {
      if (part?.match(regex)) {
        return `<a href="${part}" target="_blank" rel="noopener noreferrer">${part}</a>`;
      }
      return part;
    })
    .join("");
};

export const getTextWithoutHtml = (string: string) => {
  let strippedHtml = string?.replace(/<[^>]+>/g, "");
  return strippedHtml;
};

export const checkEmptyString = (htmlText: string): string => {
  if (!htmlText) return "";

  const comparisonString1 = "<p></p>\n";
  const comparisonString2 = "<p></p>";

  if (htmlText === comparisonString1 || htmlText === comparisonString2) {
    return "";
  }

  return htmlText;
};
export const replaceEmptyPTagsWithBr = (htmlText: string) => {
  htmlText = checkEmptyString(htmlText);

  if (!htmlText) return "";

  let withoutEmptyPTags = htmlText?.replace(/<p><\/p>/g, "<br/>");
  return withoutEmptyPTags;
};
export const convertHtmlToWhatsappText = (text: string) => {
  if (!text) return "";
  let strBold = text;
  let replacedBold = strBold
    ?.replace(/<strong>/g, "*")
    ?.replace(/<\/strong>/g, "*");
  let strItalic = replacedBold;
  let replacedItalic = strItalic
    ?.replace(/<em>/g, "_")
    ?.replace(/<\/em>/g, "_");

  let strStrike = replacedItalic;
  let replacedStrike = strStrike
    ?.replace(/<del>/g, "~")
    ?.replace(/<\/del>/g, "~");

  let strMono = replacedStrike;
  let replacedMono = strMono
    ?.replace(/<tt>/g, "```")
    ?.replace(/<\/tt>/g, "```")
    ?.replace(/<code>/g, "```")
    ?.replace(/<\/code>/g, "```");
  
  // Replace <br> tags with \n
  let strBreak = replacedMono;
  let replacedBreak = strBreak?.replace(/<br\s*\/?>/gi, "\n");

  let whatsappText: string = getTextWithoutHtml(replacedBreak || "")
    // .replace(/\n/g, "")
    .replace(/&nbsp;/g, "");
  return whatsappText;
};
export const getChannelWiseText = (text: string, type?: string) => {
  let finalText = text;
  if (!finalText) return "";
  // finalText = htmlStringToLink(finalText);

  if (type === "whatsapp" || type === "messenger" || type === "telegram") {
    finalText = convertHtmlToWhatsappText(finalText);
    return finalText;
  }

  finalText = finalText?.length > 0 ? replaceEmptyPTagsWithBr(finalText) : "";

  return finalText;
};

const getHtmlTextChannelWise = (text: string, type?: string) => {
  let finalText = text;
  if (!finalText) return "";

  if (type === "whatsapp") {
    finalText = convertWhatsappToHtmlText(text);
    return finalText;
  }

  return finalText;
};

const convertWhatsappToHtmlText = (text: string) => {
  if (!text) return "";
  let htmlText = text;
  const htmlFormat = [
    { symbol: "*", tag: "strong" },
    { symbol: "_", tag: "em" },
    { symbol: "~", tag: "del" },
    { symbol: "`", tag: "code" },
  ];
  htmlFormat.forEach(({ symbol, tag }) => {
    const regex = new RegExp(
      `(^|\\s)\\${symbol}(\\S[^${symbol}]*)\\${symbol}(\\s|$)`,
      "gm",
    );
    const match = htmlText.match(regex);
    if (!match) return;

    match.forEach((m) => {
      let formatted = m;
      for (let i = 0; i < 2; i++) {
        formatted = formatted.replace(symbol, `<${i > 0 ? "/" : ""}${tag}>`);
      }
      htmlText = htmlText.replace(m, formatted);
    });
  });
  // return `<p>${htmlText}</p>`;
  return `${htmlText}`;
};
const getProcessedLength = (input: string) => {
  // Remove all `{{ ... }}` including content inside and return the remaining string length
  return input?.replace(/\{\{.*?\}\}/g, "").length || 0;
};

const isApiVariable=(value: string) => {
  return /^\{\{.*\}\}$/.test(value);
}

export {
  getInitialEditiorText,
  getPlainTextData,
  isInIgnoredElement,
  getHTMLContent,
  getMediaDetails,
  getMediaDetailsForMediaNode,
  cleanMediaDetails,
  convertWhatsappToHtmlText,
  getHtmlTextChannelWise,
  getProcessedLength,
  isApiVariable
};
