import { Component } from "react";
import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  CompositeDecorator,
  getDefaultKeyBinding,
  ContentBlock,
} from "draft-js";
import {
  changeDepth,
  handleNewLine,
  blockRenderMap,
  getCustomStyleMap,
  extractInlineStyle,
  getSelectedBlocksType,
} from "draftjs-utils";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

// draft js utils
import FocusHandler from "./event-handler/focus";
import { hasProperty } from "./utils/common";
import blockStyleFn from "./utils/BlockStyle";
import getBlockRenderFunc from "./renderer";
import defaultToolbar from "./config/defaultToolbar";
import getLinkDecorator from "./decorators/Link";
import Controls from "./controls";

// components
import UploadSection from "./upload";
import Icon from "../Icon";

// styles
import {
  EditorMainWrapperStyled,
  EditorInnerWrapperStyled,
  UploadSectionCloseButtonStyled,
} from "./editor.styled";
import { connect, useSelector } from "react-redux";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";
import { getChannelName } from "../../../../Whatsapp/Store/FlowBuilder.selectors";

interface Props {
  bot?: { channel?: string };
  editorState: any;
  onBlur?: any;
  onFocus?: any;
  onTab?: any;
  readOnly?: any;
  onEditorStateChange: any;
  onChange?: any;
  editorRef?: any;
  mediaInfo?: MediaInfoType;
  whatsappEditorOptions?: boolean;
  hideFocusOnLoad?: boolean;
  error?: boolean;
  media?: boolean;
  useCurrentBot?: any;
  channel?: string;
  isMediaNode?: boolean;
  mediaName?: any;
}

type URL = {
  url: string;
};

type MediaInfoType = {
  file?: URL;
  image?: URL;
  video?: URL;
  type?: any;
  value?: any;
};

interface States {
  editorState: any;
  editorFocused: boolean;
  toolbar: any;
  upload: string;
  mediaInfo: MediaInfoType;
  error: boolean;
  useCurrentBot?: any;
}

const defaultMediaInfo = {
  file: { url: "" },
  image: { url: "" },
  video: { url: "" },
  value: {url: ""},
};

class UIEditor extends Component<Props, States> {
  blockRendererFn: ((block: ContentBlock) => any) | undefined;
  focusHandler: any;
  compositeDecorator: CompositeDecorator;
  wrapper: any;
  editor: any;

  constructor(props: any) {
    super(props);
    const toolbar = defaultToolbar;
    this.focusHandler = new FocusHandler();

    this.blockRendererFn = getBlockRenderFunc(
      {
        getEditorState: this.getEditorState,
        onChange: this.onChange,
      },
      props.customBlockRenderFunc,
    );
    this.compositeDecorator = this.getCompositeDecorator(toolbar);
    const editorState = this.createEditorState(this.compositeDecorator);
    extractInlineStyle(editorState);
    this.state = {
      editorState,
      editorFocused: false,
      toolbar,
      upload: props.isMediaNode ? props?.mediaName : (props?.mediaInfo ? this.getuploadType() : ""),
      mediaInfo: props?.mediaInfo ? props.mediaInfo : defaultMediaInfo,
      error: props?.error || false,
    };
  }

  getuploadType = () => {
    const { mediaInfo, isMediaNode,mediaName } = this.props;
    let uploadType: any = "";
    if(!isMediaNode){
    if (mediaInfo) {
      Object?.entries(mediaInfo)?.map((item) => {
        if (item[1]?.url !== "") {
          uploadType = item[0];
        }
      });
  }}else{
    if(mediaInfo)
    uploadType = mediaName
  }
    return uploadType;
  };

  focusEditor = () => {
    setTimeout(() => {
      if (this.editor !== null) this.editor.focus();
    });
  };

  componentDidMount() {
    // * kindly do not remove to stop focus mode onLoad
    if (this.props.hideFocusOnLoad) {
      //do nothing
    } else {
      this.focusEditor();
    }
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps === this.props) return;
    const newState: any = {};
    const { editorState, mediaInfo, mediaName, isMediaNode, onEditorStateChange } = this.props;
    if (
      hasProperty(this.props, "editorState") &&
      editorState !== prevProps.editorState
    ) {
      if (editorState) {
        newState.editorState = EditorState.set(editorState, {
          decorator: this.compositeDecorator,
        });
      } else {
        newState.editorState = EditorState.createEmpty(this.compositeDecorator);
      }
    }
    if (prevProps.editorState !== editorState) {
      extractInlineStyle(newState.editorState);
    }
    
    if (Object.keys(newState).length) this.setState(newState);
    if (isMediaNode && prevProps.mediaName !== mediaName) {
      const updatedMediaInfo = isMediaNode
        ? { ...defaultMediaInfo, value: { url: "" } }
        : { ...this.state.mediaInfo, [this.state.upload]: { url: "" } };
      this.setState(
        {
          mediaInfo: updatedMediaInfo,
          upload: ""
        },
        () => {
          if (onEditorStateChange) {
            onEditorStateChange(this.state.editorState, updatedMediaInfo);
          }
        }
      )
    }

    //* Fixed: sometimes mediaInfo wasn't updating while retriving values from api
    if (
      mediaInfo &&
      hasProperty(this.props, "mediaInfo") &&
      prevProps?.mediaInfo !== mediaInfo
    ) {
      if (
        Object.keys(mediaInfo).length === 0 &&
        Object.keys(prevProps?.mediaInfo).length === 0
      )
        return;

      this.setState({ mediaInfo, upload: this.getuploadType() });
    }
  }

  getCompositeDecorator = (toolbar: any) => {
    const decorators = [
      getLinkDecorator({
        showOpenOptionOnHover: toolbar.link.showOpenOptionOnHover,
      }),
    ];
    return new CompositeDecorator(decorators);
  };

  createEditorState = (compositeDecorator: any) => {
    let editorState;
    if (hasProperty(this.props, "editorState")) {
      if (this.props.editorState) {
        editorState = EditorState.set(this.props.editorState, {
          decorator: compositeDecorator,
        });
      }
    }

    if (!editorState) {
      editorState = EditorState.createEmpty(compositeDecorator);
    }
    return editorState;
  };

  getEditorState = () => (this.state ? this.state.editorState : null);

  changeEditorState = (contentState: any) => {
    const newContentState = convertFromRaw(contentState);
    let { editorState } = this.state;
    editorState = EditorState.push(
      editorState,
      newContentState,
      "insert-characters",
    );
    editorState = EditorState.moveSelectionToEnd(editorState);
    return editorState;
  };

  setEditorReference = (ref: any) => {
    if (this.props.editorRef) {
      this.props.editorRef(ref);
    }
    this.editor = ref;
  };

  keyBindingFn = (event: any) => {
    if (event.key === "Tab") {
      const { onTab } = this.props;
      if (!onTab || !onTab(event)) {
        const editorState = changeDepth(
          this.state.editorState,
          event.shiftKey ? -1 : 1,
          4,
        );
        if (editorState && editorState !== this.state.editorState) {
          this.onChange(editorState);
          event.preventDefault();
        }
      }
      return null;
    }

    return getDefaultKeyBinding(event);
  };

  onChange = (editorState: any) => {
    const { readOnly, onEditorStateChange } = this.props;
    if (
      !readOnly &&
      !(
        getSelectedBlocksType(editorState) === "atomic" &&
        editorState.getSelection().isCollapsed
      )
    ) {
      if (onEditorStateChange) {
        onEditorStateChange(editorState, this.state.mediaInfo); 
      }
      if (!hasProperty(this.props, "editorState")) {
        // @ts-ignore
        this.setState({ editorState }, this.afterChange(editorState));
      } else {
        this.afterChange(editorState);
      }
    }
  };

  afterChange = (editorState: any) => {
    setTimeout(() => {
      const { onChange } = this.props;
      if (onChange) {
        onChange(
          convertToRaw(editorState.getCurrentContent()),
          this.state.mediaInfo,
        );
      }
    });
  };

  handleReturn = (event: any) => {
    const { editorState } = this.state;
    const newEditorState = handleNewLine(editorState, event);
    if (newEditorState) {
      this.onChange(newEditorState);
      return true;
    }
    return false;
  };

  handleKeyCommand = (command: any) => {
    const {
      editorState,
      toolbar: { inline },
    } = this.state;
    if (inline && inline.options.indexOf(command) >= 0) {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        this.onChange(newState);
      }
    }
  };

  preventDefault = (event: any) => {
    if (event.target.tagName === "INPUT") {
      this.focusHandler.onInputMouseDown();
    } else {
      event.preventDefault();
    }
  };

  onToolbarFocus = (event: any) => {
    const { onFocus } = this.props;
    if (onFocus && this.focusHandler.isToolbarFocused()) {
      onFocus(event);
    }
  };

  onUploadClick = (uploadOption: string) => {
    this.setState({ upload: uploadOption });
  };

  onMediaSave = (url: string, type: string) => {
    const { onEditorStateChange, isMediaNode,mediaName } = this.props;
    const currentMediaInfo = this.state.mediaInfo || defaultMediaInfo;
  const updatedMediaInfo = !isMediaNode?{ ...defaultMediaInfo, [type]: { url } }: { ...defaultMediaInfo, value: { url } }
      this.setState(
        {
          mediaInfo: updatedMediaInfo,
          upload: mediaName || type,
        },
        () => {
          if (onEditorStateChange) {
            onEditorStateChange(this.state.editorState, this.state.mediaInfo);
          }
        },
      );
  };

  onMediaDelete = () => {
    const { onEditorStateChange, isMediaNode,mediaName } = this.props;
    const {upload} = this.state;
    const updatedMediaInfo = isMediaNode
      ? { ...defaultMediaInfo, value: { url: "" } }
      : { ...this.state.mediaInfo, [this.state.upload]: { url: "" } };
    this.setState(
      {
        mediaInfo: updatedMediaInfo,
        upload: ""
      },
      () => {
        // Callback to ensure parent component is updated
        if (onEditorStateChange) {
          onEditorStateChange(this.state.editorState, updatedMediaInfo);
        }
      }
    )
  };

  getStyleMap = () => ({ ...getCustomStyleMap() });

  getTextData = () => {
    const { editorState } = this.state;
    let value = "";
    if (editorState) {
      const blocks = convertToRaw(
        this.state.editorState.getCurrentContent(),
      ).blocks;
      value = blocks
        .map((block) => (!block.text.trim() && "\n") || block.text)
        .join("\n");
    }
    return value;
  };

  handleBlur = () => {
    const { onBlur } = this.props;
    if (onBlur) {
      const blocks = convertToRaw(
        this.state.editorState.getCurrentContent(),
      ).blocks;
      const value = blocks
        .map((block) => (!block.text.trim() && "\n") || block.text)
        .join("\n");
      onBlur(value);
    }
  };


  render() {
    const { editorState, toolbar, upload, mediaInfo } = this.state;
    const { channel, bot, media, error, isMediaNode, mediaName} = this.props;
    const controlProps = {
      editorState,
      onChange: this.onChange,
      onUploadClick: this.onUploadClick,
    };
    const checkUrlHidden = Object.keys(mediaInfo)

    let checkForURL: any = upload !== "" ? mediaInfo : "";
    // @ts-ignore
    checkForURL = isMediaNode? checkForURL?.value:checkForURL[upload];
    let isURL = checkForURL?.url;
    let uploadURLType = upload;
    if (!isMediaNode) {
      Object.entries(mediaInfo).map((item) => {
        if (item[1]?.url !== "" && item[0] === upload) {
          isURL = item[1]?.url;
          uploadURLType = item[0];
        }
      });
    } else {
      if ( mediaInfo?.value?.url !== "") {
        isURL = mediaInfo?.value?.url;
        uploadURLType = mediaName;
      }
    }

    return (
        <EditorMainWrapperStyled
          error={this.props.error || false}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {(isURL || uploadURLType) && (
            <Box sx={{ position: "relative", marginBottom: "10px"}}>
              {!isURL && (
                <UploadSectionCloseButtonStyled
                  onClick={() => this.setState({ upload: "" })}
                  size="medium"
                >
                  <Icon icon="close" size={12} color="#fff" />
                </UploadSectionCloseButtonStyled>
              )}
              <UploadSection
                url={isURL}
                type={uploadURLType}
                onSave={this.onMediaSave}
                onDelete={this.onMediaDelete}
              />
            </Box>
        )}
          <EditorInnerWrapperStyled>
            <Editor
              ref={this.setEditorReference}
              keyBindingFn={this.keyBindingFn}
              editorState={editorState}
              onChange={this.onChange}
              blockStyleFn={blockStyleFn}
              customStyleMap={this.getStyleMap()}
              blockRendererFn={this.blockRendererFn}
            // @ts-ignore
              handleKeyCommand={this.handleKeyCommand}
              blockRenderMap={blockRenderMap}
              onBlur={this.handleBlur}
              placeholder="Type your message here"
            />
          </EditorInnerWrapperStyled>
          {
          !isMediaNode && (
            <Box onMouseDown={this.preventDefault} onFocus={this.onToolbarFocus} style={{ paddingTop: "10px" }}>
              <Stack direction={"row"} spacing={2}>
                {toolbar.options.map((opt: any, index: number) => {
                  if (typeof this.props?.media === "boolean") {
                    if (!this.props?.media && opt === "link") return null;
                  }

                  // Remove "link" only if the channel is "whatsapp"
                  if (opt === "link" && this.props.channel === "whatsapp") {
                    return null;
                  }

                  // @ts-ignore
                  const Control = Controls[opt];
                  const config = toolbar[opt];

                  return <Control key={index} {...controlProps} config={config} />;
                })}
              </Stack>
            </Box>
          ) 
          }
        </EditorMainWrapperStyled>
    );
  }
}

const mapStateToProps = (state: any) => ({
  channel: getChannelName(state),
});

export default connect(mapStateToProps)(UIEditor);

