import { useState, useRef, useEffect } from "react"; // Add useRef
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

// components
import Icon from "../../../Icon";
import UIDialog from "./../../../Dialog";
import DeleteButton from "./delete-button";
import Button from "../../../Button";
import Input from "../../../Input";
import NodeType from "../../../NodeType";

// styles
import { MediaPlaceholderStyled } from "./common.styles";
import { isApiVariable } from "../../../../Utils/editor";
import { getCorrectMediaFormats, getCorrectMediaSize, getMediaInfo } from "../../../../Nodes/WhatsApp/InteractiveMessage/helper";
import { ChatbotConsoleService } from "../../../../../../Services/Apis/ChatbotConsoleService";
import { useNotification } from "../../../../../../Hooks/useNotification/useNotification";


const Image = (props: any) => {
  const [showModal, setShowModal] = useState(false);
  const [url, setURL] = useState(props.url);
  const [showUploadArea, setShowUploadArea] = useState(props?.url ? false : true);
  const { showAlert } = useNotification();
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null); 
  const toggleDialog = () => {
    setShowModal(!showModal);
  };

  const onChange = (event: any) => {
    setURL(event.target.value);
  };

  const onSave = () => {
    setShowUploadArea(false);
    toggleDialog();
    props.onSave && props.onSave(url);
  };

  const handleDeleteClick = () => {
    setShowDeleteConfirmModal(true);
  };

  const handleConfirmDelete = () => {
    setURL("");
    setShowUploadArea(true);
    setShowDeleteConfirmModal(false);
    props.onDelete && props.onDelete();
  };

  const validateImgURL = (url: string) => {
    const isVariable =  isApiVariable(url)
    if(isVariable){
      return isVariable
    }
    
    const regex = /^https?:\/\/.*\/.*\.(png|gif|webp|jpeg|jpg)\??.*$/gim;
    return regex.test(url);
  };

  const onMediaChange = (file: any) => {
    if (file) {
      const formData = new FormData();
      formData.append("source", file);
      ChatbotConsoleService.uploadWhatsappMedia(formData, true)
        .then((response: any) => {
          const uploadURL = getMediaInfo(response?.data, "image");
          setURL(uploadURL?.link);
          console.log(uploadURL)
          props.onSave && props.onSave(uploadURL?.link);
          setShowUploadArea(false);
        })
        .catch((error: any) => {
          showAlert("Media upload failed", "error");
        })
        .finally(() => {
          setTimeout(() => {
          }, 1000);
        });
    }
  };

  const handleUploadClick = (event: any) => {
    let file = event.target.files[0];
    const reader = new FileReader();
    if (!reader || !file) {
      return;
    }
    reader.readAsDataURL(file);
    const allowedMediaSize = getCorrectMediaSize("image");
    if (allowedMediaSize && file.size > allowedMediaSize) {
      setTimeout(() => {
        showAlert(
          `Image size must be smaller than ${allowedMediaSize / 1000000} MB`, "error"
        );
      }, 1);
      return;
    }
    reader.onloadend = (e: any) => {
      var mimeType = e?.target?.result
        ?.split(",")[0]
        .split(":")[1]
        .split(";")[0];

      if (!getCorrectMediaFormats("image").includes(mimeType)) {
        setTimeout(() => {
          showAlert("Invalid File format", "error");
        }, 1);
        return;
      }
      onMediaChange(file);
    };
  };

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return showUploadArea ? (
    <>
      <MediaPlaceholderStyled>
        <Icon icon="add-image" size={24} color="#5B73E8" />
        <Typography sx={{ my: "10px" }}>
          Drop JPG, PNG File to upload or
        </Typography>
        <Stack direction="row" spacing={"16px"}>
          <Button
            variant="outlined"
            startIcon={<Icon icon="link" size={16} />}
            onClick={toggleDialog}
          >
            Image URL
          </Button>

          <Button
            variant="outlined"
            startIcon={<Icon icon="upload" size={16} />}
            onClick={triggerFileInput} 
          >
            Browse
          </Button>
          <input
            hidden
            ref={fileInputRef} 
            id={`image-upload`}
            name={`image-upload`}
            required={true}
            accept={getCorrectMediaFormats("image").toString()}
            type="file"
            onChange={handleUploadClick}
          />
        </Stack>
      </MediaPlaceholderStyled>
      <UIDialog
        className="ignore-onClickOutside"
        open={showModal}
        onClose={toggleDialog}
        sx={{ minWidth: "420px" }}
      >
        <UIDialog.Header component="div" sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="space-between">
            <NodeType text="Image URL" icon="video" color="#84CBFF" />
            <Box
              sx={{
                px: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton onClick={toggleDialog}>
                <Icon icon="close" size={16} />
              </IconButton>
            </Box>
          </Stack>
        </UIDialog.Header>
        <UIDialog.Body sx={{ px: "16px", pb: "8px" }}>
          <Box sx={{ minWidth: "420px" }}>
            <Typography
              component="label"
              sx={{
                color: "#7E8392",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400,
              }}
            >
              URL
              <Input
                placeholder="link"
                id="image-link-input"
                size="small"
                name="url"
                onChange={onChange}
                onBlur={onChange}
                value={url}
                fullWidth
                error={url && url !== "" && !validateImgURL(url)}
              />
            </Typography>
          </Box>
        </UIDialog.Body>
        <UIDialog.Footer
          sx={{ justifyContent: "space-between", px: "16px", pb: "8px" }}
        >
          <Button variant="outlined" onClick={toggleDialog}>
            cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Icon icon="save" size={16} />}
            onClick={onSave}
            disabled={!url || !validateImgURL(url)}
          >
            Save
          </Button>
        </UIDialog.Footer>
      </UIDialog>
    </>
  ) : (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ position: "absolute", top: "16px", right: "16px" }}>
        <DeleteButton onClick={handleDeleteClick} />
      </Box>
      <Box
        component="img"
        src={url}
        sx={{
          width: "100%",
          height: "230px",
          borderRadius: "6px",
          objectFit: "cover",
        }}
      />
      <UIDialog
        className="ignore-onClickOutside"
        open={showDeleteConfirmModal}
        onClose={() => setShowDeleteConfirmModal(false)}
        sx={{ minWidth: "420px" }}
      >
        <UIDialog.Header component="div" sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="space-between">
            <NodeType text="Image URL" icon="video" color="#84CBFF" />
            <Box
              sx={{
                px: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton onClick={() => setShowDeleteConfirmModal(false)}>
                <Icon icon="close" size={16} />
              </IconButton>
            </Box>
          </Stack>
        </UIDialog.Header>
        <UIDialog.Body sx={{ px: "16px", pb: "8px" }}>
          <Box sx={{ minWidth: "420px" }}>
            <Typography
              component="label"
              sx={{
                color: "#7E8392",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400,
              }}
            >
              Are you sure you want to delete this image?
            </Typography>
          </Box>
        </UIDialog.Body>
        <UIDialog.Footer
          sx={{ justifyContent: "space-between", px: "16px", pb: "8px" }}
        >
          <Button
            variant="outlined"
            onClick={() => setShowDeleteConfirmModal(false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="error"
            startIcon={<Icon icon="delete" size={16} />}
            onClick={handleConfirmDelete}
          >
            Yes
          </Button>
        </UIDialog.Footer>
      </UIDialog>
    </Box>
  );
};

export default Image;
