// vendors
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// components

// styles
import { AppToolTip } from "../../../../Components/UiComponents/AppToolTip/AppToolTip";
import { ReactComponent as CopyIcon } from "../../../../assets/images/copy-icon.svg";
import {
  ListItemButtonStyled,
  ListItemStyled,
  NodeCountTextStyled
} from "./drawer.styled";
// import { Icon, IconButton } from "@mui/material";
import IconButton from "@mui/material/IconButton";
// components
import Icon from "../Icon";

interface Props {
  flow: Flow;
  openChild: boolean;
  order: number;
  onItemClick: () => void;
  onDeleteClick: () => void;
  handleCopyClick: (id: any) => void;
}

const MainList = (props: Props) => {
  const { onItemClick, onDeleteClick, handleCopyClick, flow, order, openChild } = props;
  return (
    <ListItemStyled disablePadding openChild={openChild}>
      <ListItemButtonStyled onClick={onItemClick}>
        <Typography variant="subtitle1" sx={{ mr: "8px" }}>
          {order}
        </Typography>
        <AppToolTip
          iconWrapperStyle={{
            cursor: "pointer"
          }}
          tooltipProps={{
            title: `${flow.name}`,
            placement: "top",
          }}
          iconButtonProps={{ disableRipple: true }}
          IconComponent={""}
        >
          <Typography
            fontWeight={500}
            variant="subtitle1"
            color="default.black"
            flex={1}
            sx={{
              textDecoration: "none",
              maxWidth: "250px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {flow.name}
          </Typography>
        </AppToolTip>
      </ListItemButtonStyled>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton onClick={() => handleCopyClick(flow.qid)} size="small">
          <Icon icon="copy-icon" size={16} />
          <CopyIcon fontSize={16} />

        </IconButton>
      </Box>
      <Box
        sx={{
          height: "40px",
          width: "40px",
        }}
      >
        <NodeCountTextStyled>
          {flow.nodeCounts === 0 ? 0 : flow.nodeCounts > 9 ? flow.nodeCounts : `0${flow.nodeCounts}`}
        </NodeCountTextStyled>

      </Box>
    </ListItemStyled>
  );
};
export default MainList;
