import styled from "styled-components";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
  display: ${(props: any) => props.animationState === "hidden" ? "none" : "block"};
`;

export const VersionPopupContainer = styled.div`
  position: fixed;
  top: 50%;
  bottom: 50%;
  left: 50%;
  width: 510px;
  transform: translate(-50%, -50%) ${(props: any) => props.animationState === "entering" ? "scale(1)" : ""};
  height: 329px;
  background-color: #FFF;
  color: #000;
  padding: 22px 14px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0,0,0,0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  z-index: 9999;
  opacity: ${(props: any) => props.animationState === "hidden" ? 0 : 1};
  animation: ${(props: any) => props.animationState === "entering" ? "rubberBand 1s ease-out" : "none"};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const IconCircle = styled.div`
  background-color: #E5EAFF;
  height: 106px;
  width: 106px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
`;

export const TextContainer = styled.div`
  text-align: center;
`;

export const TitleText = styled.p`
  color: #101010;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 5px;
`;

export const VersionText = styled.p`
  font-size: 19px;
  font-weight: 400;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const CancelButton = styled.button`
  width: 155px;
  height: 48px;
  color: #211C52;
  border: 0px;
  cursor: pointer;
  font-size: 15px;
  border-radius: 7px;
`;

export const UpdateButton = styled.button`
  width: 155px;
  height: 48px;
  background-color: #211C52;
  border: 0px;
  color: #F5F5F5;
  cursor: pointer;
  font-size: 15px;
  border-radius: 7px;
`;

export const AnimationStyles = styled.style`
  @keyframes rubberBand {
    0% {
      transform: translate(-50%, -50%) scale(0.3);
    }
    30% {
      transform: translate(-50%, -50%) scale(1.25);
    }
    40% {
      transform: translate(-50%, -50%) scale(0.9);
    }
    50% {
      transform: translate(-50%, -50%) scale(1.15);
    }
    65% {
      transform: translate(-50%, -50%) scale(0.95);
    }
    75% {
      transform: translate(-50%, -50%) scale(1.05);
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }
`;
