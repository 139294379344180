// vendors
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";

// component
import MessagePreview from "../../Components/MessagePreview";
import Editor from "../../Components/Editor";
import Configuration from "./configuration";

// slice
import {
  getLanguage,
  getActiveNode,
  updateModifiedData,
  setDateConfiguration,
} from "../../Slices/main.slice";
import { useAppDispatch } from "../../../../Store";

// utils
import {
  getResponse,
  getText,
  getInitialEditiorText,
  getPlainTextData,
  getHTMLContent,
  cleanMediaDetails,
  getMediaDetails,
} from "../../Utils";
import { fromInit, toInit } from "./utils";

const DateNode = (props: NodeProps) => {
  const { node } = props;
  const language = useSelector(getLanguage);
  const activeNode = useSelector(getActiveNode);
  const response = getResponse(node, language);
  const text = getText(response);
  const media = getMediaDetails(response);
  const [editorState, setEditorState] = useState(
    getInitialEditiorText(text || "")
  );
  const [excludedDates, updateExcludedDates] = useState<any>(
    {
      excludeAllDays: node?.excluded?.days || [],
      excludeSpecificDates: node?.excluded?.dates || [],
    }
  );
  const [selectedDates, updateSelectedDates] = useState<any>(
    {
      dateFormat: node?.dateFormat || "",
      from: node?.from || fromInit,
      to: node?.to || toInit,
      dateRange: node?.dateRange || false,
    }
  );
  const isActiveNode = activeNode === node.nodeId;
  const dispatch = useAppDispatch();

  useEffect(() => {
    const data = {
      ...selectedDates,
      excluded: {
        dates: excludedDates.excludeSpecificDates,
        days: excludedDates.excludeAllDays,
      },
    };
    isActiveNode && dispatch(setDateConfiguration(data || {}));
  }, [isActiveNode, selectedDates, excludedDates]);

  const onEditorStateChange = (editorState: any, mediaInfo: any) => {
    setEditorState(editorState);

    if (cleanMediaDetails(mediaInfo)) {
      dispatch(
        updateModifiedData([
          {
            id: uuid(),
            value: getHTMLContent(editorState),
            type: "text",
            media: cleanMediaDetails(mediaInfo),
          },
        ])
      );
    } else {
      dispatch(
        updateModifiedData([
          {  id: uuid(), value: getHTMLContent(editorState), type: "text" },
        ])
      );
    }
  };

  {/*const handleSaveClick = (selectedDates: any, exculdedDates: any) => {
    updateSelectedDates(selectedDates);
    updateExcludedDates(exculdedDates);
    const data = {
      ...selectedDates,
      excluded: {
        dates: exculdedDates.excludeSpecificDates,
        days: exculdedDates.excludeAllDays,
      },
    };
    dispatch(setDateConfiguration(data));
  };*/}

  const handleSaveClick = (selectedDates: any, excludedDates: any) => {
    const formatDate = (date: any) => {
      if (!date) return "";
      const parsedDate = new Date(date);
      const day = String(parsedDate.getDate()).padStart(2, "0");
      const month = String(parsedDate.getMonth() + 1).padStart(2, "0"); 
      const year = parsedDate.getFullYear();
      return `${day}/${month}/${year}`;
    };
  
    const formattedData = {
      ...selectedDates,
      from: { ...selectedDates.from, date: formatDate(selectedDates.from.date) },
      to: { ...selectedDates.to, date: formatDate(selectedDates.to.date) },
      excluded: {
        dates: excludedDates.excludeSpecificDates,
        days: excludedDates.excludeAllDays,
      },
    };
    updateSelectedDates(formattedData);
    updateExcludedDates(excludedDates);
    dispatch(setDateConfiguration(formattedData));
  };
  

  const handleClose = () => {
    updateSelectedDates({
      dateFormat: node?.dateFormat,
      from: node?.from,
      to: node?.to,
      dateRange: node?.dateRange,
    });
    updateExcludedDates({
      excludeAllDays: node?.excluded?.days,
      excludeSpecificDates: node?.excluded?.dates,
    });
  };

  return (
    <>
      <Box p={"8px 16px"}>
        {isActiveNode ? (
          <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            error={getPlainTextData(editorState) === ""}
            mediaInfo={media}
            media={false}
          />
        ) : (
          <MessagePreview text={text} mediaDetails={media} />
        )}
      </Box>
      {isActiveNode && (
        <>
          <Divider />
          <Box p={"16px"} display={"flex"} justifyContent={"space-between"}>
            <Typography>Date Configuration</Typography>
            <Configuration
              node={node}
              onSave={handleSaveClick}
              selectedDates={selectedDates}
              excludedDates={excludedDates}
              onClose={handleClose}
            />
          </Box>
        </>
      )}
    </>
  );
};
export default DateNode;
