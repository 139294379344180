// vendors
import { useRef, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import isUrl from "is-url";
import ReactPlayer from 'react-player'

// components
import DeleteButton from "./delete-button";
import Icon from "../../../Icon";
import UIDialog from "./../../../Dialog";
import NodeType from "../../../NodeType";
import UIButton from "../../../Button";
import Input from "../../../Input";

// styles
import { MediaPlaceholderStyled } from "./common.styles";
import { isApiVariable } from "../../../../Utils/editor";
import { useNotification } from "../../../../../../Hooks/useNotification/useNotification";
import { ChatbotConsoleService } from "../../../../../../Services/Apis/ChatbotConsoleService";
import { getCorrectMediaFormats, getCorrectMediaSize, getMediaInfo } from "../../../../Nodes/WhatsApp/InteractiveMessage/helper";

const Video = (props: any) => {
  const [showModal, setShowModal] = useState(false);
  const [url, setURL] = useState(props?.url);
  const [showUploadArea, setShowUploadArea] = useState(
    props?.url ? false : true
  );
  const { showAlert } = useNotification();
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null); 
  const toggleDialog = () => {
    setShowModal(!showModal);
  };

  const onChange = (event: any) => {
    setURL(event.target.value);
  };

  const onSave = () => {
    setShowUploadArea(false);
    toggleDialog();
    props.onSave && props.onSave(url);
  };

  const handleDeleteClick = () => {
    setShowDeleteConfirmModal(true);
  };

  const handleConfirmDelete = () => {
    setURL("");
    setShowUploadArea(true);
    setShowDeleteConfirmModal(false);
    props.onDelete && props.onDelete();
  };

  const onMediaChange = (file: any) => {
      if (file) {
        const formData = new FormData();
        formData.append("source", file);
        ChatbotConsoleService.uploadWhatsappMedia(formData, true)
          .then((response: any) => {
            const uploadURL = getMediaInfo(response?.data, "video");
            setURL(uploadURL?.link);
            console.log(uploadURL)
            props.onSave && props.onSave(uploadURL?.link);
            setShowUploadArea(false);
          })
          .catch((error: any) => {
            showAlert("Media upload failed", "error");
          })
          .finally(() => {
            setTimeout(() => {
            }, 1000);
          });
      }
    };

   const handleUploadClick = (event: any) => {
      let file = event.target.files[0];
      const reader = new FileReader();
      if (!reader || !file) {
        return;
      }
      reader.readAsDataURL(file);
      const allowedMediaSize = getCorrectMediaSize("video");
      if (allowedMediaSize && file.size > allowedMediaSize) {
        setTimeout(() => {
          showAlert(
            `Video size must be smaller than ${allowedMediaSize / 1000000} MB`, "error"
          );
        }, 1);
        return;
      }
      reader.onloadend = (e: any) => {
        var mimeType = e?.target?.result
          ?.split(",")[0]
          .split(":")[1]
          .split(";")[0];
  
        if (!getCorrectMediaFormats("video").includes(mimeType)) {
          setTimeout(() => {
            showAlert("Invalid File format", "error");
          }, 1);
          return;
        }
        onMediaChange(file);
      };
    };

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return showUploadArea ? (
    <>
      <MediaPlaceholderStyled>
        <Icon icon="video" size={24} color="#5B73E8" />
        <Stack direction="row" spacing={"16px"} marginTop={"10px"}>
          <UIButton
            variant="outlined"
            startIcon={<Icon icon="video" size={16} />}
            onClick={toggleDialog}
          >
            Video URL
          </UIButton>
          <UIButton
            sx={{ mt: "10px" }}
            variant="outlined"
            onClick={triggerFileInput}
          >
            Browse
          </UIButton>
          <input
            hidden
            ref={fileInputRef}
            id={`video-upload`}
            name={`video-upload`}
            required={true}
            accept={getCorrectMediaFormats("video").toString()}
            type="file"
            onChange={handleUploadClick}
          />
        </Stack>
      </MediaPlaceholderStyled>
      <UIDialog
        className="ignore-onClickOutside"
        onClick={(e) => {
          e.stopPropagation();
        }}
        open={showModal}
        onClose={toggleDialog}
        sx={{ minWidth: "420px" }}
      >
        <UIDialog.Header component="div" sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="space-between">
            <NodeType text="Video URL" icon="video" color="#FF8484" />
            <Box
              sx={{
                px: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton onClick={toggleDialog}>
                <Icon icon="close" size={16} />
              </IconButton>
            </Box>
          </Stack>
        </UIDialog.Header>
        <UIDialog.Body onClick={(e) => {
          e.stopPropagation();
        }} sx={{ px: "16px", pb: "8px", width: "100%" }}>
          <Typography
            component="label"
            sx={{
              color: "#7E8392",
              fontSize: "12px",
              lineHeight: "18px",
              fontWeight: 400,
            }}
          >
            URL (Supports Youtube, Vimeo, Vidyard, loom or Wistia.)
            <Input
              placeholder="link"
              id="video-link-input"
              size="small"
              name="url"
              onChange={onChange}
              onBlur={onChange}
              value={url}
              fullWidth
              error={url && url !== "" && !isUrl(url) && !isApiVariable(url)}

            />
          </Typography>
        </UIDialog.Body>
        <UIDialog.Footer
          sx={{ justifyContent: "space-between", px: "16px", pb: "8px" }}
        >
          <UIButton variant="outlined" color="secondary" onClick={toggleDialog}>
            cancel
          </UIButton>
          <UIButton
            color="primary"
            variant="contained"
            startIcon={<Icon icon="save" size={16} />}
            onClick={onSave}
            disabled={!url || (!isUrl(url) && !isApiVariable(url))}
          >
            Save
          </UIButton>
        </UIDialog.Footer>
      </UIDialog>
    </>
  ) : (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ position: "absolute", top: "16px", right: "16px", zIndex: 9999}}>
        <DeleteButton onClick={handleDeleteClick} />
      </Box>
      <ReactPlayer url={url} width={"100%"} height="230px" controls={true}/>
      <UIDialog
        className="ignore-onClickOutside"
        open={showDeleteConfirmModal}
        onClose={() => setShowDeleteConfirmModal(false)}
        sx={{ minWidth: "420px" }}
      >
        <UIDialog.Header component="div" sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="space-between">
            <NodeType text="Remove URL" icon="video" color="#84CBFF" />
            <Box
              sx={{
                px: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton onClick={() => setShowDeleteConfirmModal(false)}>
                <Icon icon="close" size={16} />
              </IconButton>
            </Box>
          </Stack>
        </UIDialog.Header>
        <UIDialog.Body sx={{ px: "16px", pb: "8px", minWidth: "420px" }}>
          <Typography
            component="label"
            sx={{
              color: "#7E8392",
              fontSize: "12px",
              lineHeight: "18px",
              fontWeight: 400,
            }}
          >
            Are you sure you want to remove this video?
          </Typography>
        </UIDialog.Body>
        <UIDialog.Footer
          sx={{ justifyContent: "space-between", px: "16px", pb: "8px" }}
        >
          <UIButton
            color="secondary"
            variant="outlined"
            onClick={() => setShowDeleteConfirmModal(false)}
          >
            No
          </UIButton>
          <UIButton
            variant="contained"
            color="error"
            startIcon={<Icon icon="delete" size={16} />}
            onClick={handleConfirmDelete}
          >
            Yes
          </UIButton>
        </UIDialog.Footer>
      </UIDialog>
    </Box>
  );
};
export default Video;
