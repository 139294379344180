// vendors
import { useEffect, useState } from "react";
import { Stack, styled } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ReactPlayer from "react-player";

// @ts-ignore
import parse from "html-react-parser";
import { theme } from "../../../../Customization/Theme";
import Icon from "../Icon";

type URL = {
  url: string;
};

type MediaInfoType = {
  file?: URL;
  image?: URL;
  video?: URL;
  value?: any;
  type?: any;
};

interface MessagePreviewProps {
  text: string;
  mediaDetails?: MediaInfoType;
  isMediaNode?: boolean;
}

const MessagePreviewStyled = styled(Box)(() => ({
  backgroundColor: theme.palette.secondary.main,
  padding: theme.spacing(1.5),
  borderRadius: "4px",
  color: theme.palette?.default?.darkGrey,
  fontSize: "14px",
  lineHeight: "21px",
  fontWeight: 400,
  wordBreak: "break-word",
  "& p": {
    margin: 0,
  },
}));

const MessagePreview = (props: MessagePreviewProps) => {
  const { mediaDetails, text, isMediaNode } = props;
  const [mediaURL, setMediaURL] = useState("");
  const [mediaURLType, setMediaURLType] = useState("");

  useEffect(() => {
    setMediaURL("");
    setMediaURLType("");
    if (!mediaDetails) return;

    if (isMediaNode) {
      const url = mediaDetails?.value?.url || "";
      const type = mediaDetails?.type || "";
      setMediaURL(url);
      setMediaURLType(type);
    } else {
      Object.entries(mediaDetails).forEach(([key, value]) => {
        if (value?.url && value.url !== "") {
          setMediaURL(value.url);
          setMediaURLType(key);
        }
      });
    }
  }, [mediaDetails, isMediaNode]);

  return (
    <MessagePreviewStyled>
      {mediaURLType === "image" && mediaURL && (
        <Box
          component="img"
          src={mediaURL}
          sx={{ width: "100%", objectFit: "cover" }}
          onError={(e) => console.error("Image load error:", e)}
        />
      )}
      {mediaURLType === "video" && mediaURL && (
        <ReactPlayer
          url={mediaURL}
          width={"100%"}
          height="230px"
          controls={true}
          onError={(e) => console.error("Video load error:", e)}
        />
      )}
      {mediaURLType === "document" && mediaURL && (
          <Stack
            direction="row"
            spacing="16px"
            sx={{ px: "20px", py: "16px", width: "90%" }}
          >
            <Box>
              <Icon icon="document" size={24} color="#5B73E8" />
            </Box>
          <Typography
            color={"#5B73E8"}
            variant="body1"
            component={"a"}
            href={mediaURL}
            target="_blank"
            sx={{ textDecoration: "none", fontSize: "16px" }}
          >
            {mediaURL?.substring(
              mediaURL?.lastIndexOf("_") + 1,
            )}
          </Typography>
          </Stack>
        )
      }
      <Typography
        sx={{
          fontSize: "14px",
          lineHeight: "21px",
          fontWeight: 400,
          color: "#7E8392",
          ...(mediaURLType && mediaURL && { mt: "12px" }),
        }}
        component="div"
      >
        {text && typeof text === "string" && parse(text)}
      </Typography>
    </MessagePreviewStyled>
  );
};

export default MessagePreview;