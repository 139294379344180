// vendors
import { useState } from "react";
import Box from "@mui/material/Box";

// components
import UIDatePicker from "./datepicker";
import MultiSelect from "./multiselect";

// utils
import { options, findMatch } from "./utils";

// styled
import { DropdownLabelStyled } from "./date.styled";

interface Props {
  onChange: ({
    excludeAllDays,
    excludeSpecificDates,
  }: {
    excludeAllDays: string[];
    excludeSpecificDates: string[];
  }) => void;
  excludeDates: {
    excludeAllDays: string[];
    excludeSpecificDates: string[];
  };
}

const ExcludeDates = (props: Props) => {
  const { onChange, excludeDates } = props;
  const [selectedList, setSelected] = useState<string[]>(
    excludeDates.excludeAllDays || []
  );
  const [excludedDaysList, updateExcludedDaysList] = useState<string[]>(
    excludeDates.excludeSpecificDates || []
  );

  const handleDropdownChange = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selectedList.length === options.length ? [] : options);
      return;
    }
    setSelected(value);
    onChange({ excludeAllDays: value, excludeSpecificDates: excludedDaysList });
  };

  const handleDeleteItem = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    event.preventDefault();
    setSelected((current) => current.filter((iCurrent) => iCurrent !== value));
  };

  const handleCalendarChange = (dates: string[]) => {
    updateExcludedDaysList(dates);
    onChange({
      excludeAllDays: selectedList,
      excludeSpecificDates: dates,
    });
  };

  return (
    <>
      <Box sx={{ mb: "10px", mt: "16px" }}>
        <MultiSelect
          onChange={handleDropdownChange}
          selectedList={selectedList}
          onDelete={handleDeleteItem}
        />
      </Box>
      <Box
        sx={{
          marginBottom: "12px",
          "& .MuiInputBase-adornedStart": {
            paddingLeft: "12px",
          },
        }}
      >
        <DropdownLabelStyled>Exclude Specific Dates</DropdownLabelStyled>
      </Box>
      <Box sx={{ border: "1px solid #F5F6F8" }}>
        <UIDatePicker
          disabledDays={findMatch(options, selectedList)}
          onChange={handleCalendarChange}
          dates={excludedDaysList}
        />
      </Box>
    </>
  );
};

export default ExcludeDates;
