import { useState, useEffect } from "react";

const VERSION_URL = "/version.json"; 
const LOCAL_STORAGE_KEY = "app_version"; 

interface VersionData {
  version: string;
}

const useVersionCheck = (isAuthenticated: boolean) => {
  const [isUpdateAvailable, setIsUpdateAvailable] = useState<boolean>(false);
  const [latestVersion, setLatestVersion] = useState<string | null>(null);
  const [currentVersion, setCurrentVersion] = useState<string | null>(null);

  const checkForUpdates = async () => {

    if (!isAuthenticated) return;

    try {
      const response = await fetch(`${VERSION_URL}?t=${Date.now()}`, {
        cache: "no-store", 
      });

      if (!response.ok) {
        throw new Error("Failed to fetch version.json");
      }

      const data: VersionData = await response.json();
      const fetchedLatestVersion = data.version;
      setLatestVersion(fetchedLatestVersion);

      let storedVersion = localStorage.getItem(LOCAL_STORAGE_KEY);

      if (!storedVersion) {
        localStorage.setItem(LOCAL_STORAGE_KEY, fetchedLatestVersion);
        storedVersion = fetchedLatestVersion;
      }

      setCurrentVersion(storedVersion);

      const isOutdated = storedVersion !== fetchedLatestVersion;
      const isDismissedInSession = sessionStorage.getItem("update_dismissed") === "true";

      setIsUpdateAvailable(isOutdated && !isDismissedInSession);
    } catch (error) {
      console.error("Error checking for updates:", error);
    }
  };

  useEffect(() => {
    sessionStorage.removeItem("update_dismissed");
    if (isAuthenticated) {
      checkForUpdates();
      const interval = setInterval(checkForUpdates, 300000); 
      return () => clearInterval(interval);
    }else{
      setIsUpdateAvailable(false);
    }
  }, [isAuthenticated]);


  const updateApp = () => {
    if (latestVersion) {
      localStorage.setItem(LOCAL_STORAGE_KEY, latestVersion); 
      setIsUpdateAvailable(false);
      sessionStorage.removeItem("update_dismissed");
      window.location.reload(); 
    }
  };


  const dismissPopup = () => {
    setIsUpdateAvailable(false);
    sessionStorage.setItem("update_dismissed", "true"); 
  };

  return { isUpdateAvailable, updateApp, dismissPopup, currentVersion, latestVersion };
};

export default useVersionCheck;