// vendors
import { useRef, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

// components
import Icon from "../../../Icon";
import UIDialog from "./../../../Dialog";
import DeleteButton from "./../handlers/delete-button";
import UIButton from "../../../Button";
import NodeType from "../../../NodeType";
import Input from "../../../Input";

// styles
import {
  MediaPlaceholderStyled,
  DocumentUploadedSectionStyled,
} from "./common.styles";
import { useNotification } from "../../../../../../Hooks/useNotification/useNotification";
import { ChatbotConsoleService } from "../../../../../../Services/Apis/ChatbotConsoleService";
import { getCorrectMediaFormats, getCorrectMediaSize, getMediaInfo } from "../../../../Nodes/WhatsApp/InteractiveMessage/helper";

const Document = (props: any) => {
  const [showModal, setShowModal] = useState(false);
  const [url, setURL] = useState(props.url);
  const [showUploadArea, setShowUploadArea] = useState(
    props?.url ? false : true
  );
  const { showAlert } = useNotification();
  const fileInputRef = useRef<HTMLInputElement>(null); 
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const toggleDialog = () => {
    setShowModal(!showModal);
  };

  const onChange = (event: any) => {
    setURL(event.target.value);
  };

  const onSave = () => {
    setShowUploadArea(false);
    toggleDialog();
    props.onSave && props.onSave(url);
  };

  const handleDeleteClick = () => {
    setShowDeleteConfirmModal(true);
  };

  const handleConfirmDelete = () => {
    setURL("");
    setShowUploadArea(true);
    setShowDeleteConfirmModal(false);
    props.onDelete && props.onDelete();
  };

  const onMediaChange = (file: any) => {
      if (file) {
        const formData = new FormData();
        formData.append("source", file);
        ChatbotConsoleService.uploadWhatsappMedia(formData, true)
          .then((response: any) => {
            const uploadURL = getMediaInfo(response?.data, "document");
            setURL(uploadURL?.link);
            console.log(uploadURL)
            props.onSave && props.onSave(uploadURL?.link);
            setShowUploadArea(false);
          })
          .catch((error: any) => {
            showAlert("Media upload failed", "error");
          })
          .finally(() => {
            setTimeout(() => {
            }, 1000);
          });
      }
    };
  
    const handleUploadClick = (event: any) => {
      let file = event.target.files[0];
      const reader = new FileReader();
      if (!reader || !file) {
        return;
      }
      reader.readAsDataURL(file);
      const allowedMediaSize = getCorrectMediaSize("document");
      if (allowedMediaSize && file.size > allowedMediaSize) {
        setTimeout(() => {
          showAlert(
            `Document size must be smaller than ${allowedMediaSize / 1000000} MB`, "error"
          );
        }, 1);
        return;
      }
      reader.onloadend = (e: any) => {
        var mimeType = e?.target?.result
          ?.split(",")[0]
          .split(":")[1]
          .split(";")[0];
  
        if (!getCorrectMediaFormats("document").includes(mimeType)) {
          setTimeout(() => {
            showAlert("Invalid File format", "error");
          }, 1);
          return;
        }
        onMediaChange(file);
      };
    };
  
    const triggerFileInput = () => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    };

  return showUploadArea ? (
    <>
      <MediaPlaceholderStyled>
        <Icon icon="document" size={24} color="#5B73E8" />
        <Typography sx={{ my: "10px" }}>Drag and drop file or</Typography>
        <Stack direction="row" spacing={"16px"}>
          <UIButton
            variant="outlined"
            startIcon={<Icon icon="document" size={16} />}
            onClick={toggleDialog}
          >
            File
          </UIButton>
          <UIButton
            sx={{ mt: "10px" }}
            variant="outlined"
            onClick={triggerFileInput}
          >
            Browse
          </UIButton>
          <input
            hidden
            ref={fileInputRef}
            id={`document-upload`}
            name={`document-upload`}
            required={true}
            accept={getCorrectMediaFormats("document").toString()}
            type="file"
            onChange={handleUploadClick}
          />
        </Stack>
      </MediaPlaceholderStyled>
      <UIDialog
        className="ignore-onClickOutside"
        open={showModal}
        onClose={toggleDialog}
        sx={{ minWidth: "420px" }}
      >
        <UIDialog.Header component="div" sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="space-between">
            <NodeType text="File URL" icon="document" color="#FFB084" />
            <Box
              sx={{
                px: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton onClick={toggleDialog}>
                <Icon icon="close" size={16} />
              </IconButton>
            </Box>
          </Stack>
        </UIDialog.Header>
        <UIDialog.Body sx={{ px: "16px", pb: "8px", width: "420px" }}>
          <Typography
            component="label"
            sx={{
              fontSize: "12px",
              lineHeight: "18px",
              fontWeight: 400,
              color: "#7E8392",
            }}
          >
            URL*
            <Input
              placeholder="link"
              id="document-link"
              size="small"
              name="url"
              onChange={onChange}
              onBlur={onChange}
              value={url}
              fullWidth
            />
          </Typography>
        </UIDialog.Body>
        <UIDialog.Footer
          sx={{ justifyContent: "space-between", px: "16px", pb: "8px" }}
        >
          <UIButton color="secondary" variant="outlined" onClick={toggleDialog}>
            cancel
          </UIButton>
          <UIButton
            color="primary"
            variant="contained"
            startIcon={<Icon icon="save" size={16} />}
            onClick={onSave}
          >
            Save
          </UIButton>
        </UIDialog.Footer>
      </UIDialog>
    </>
  ) : (
    <DocumentUploadedSectionStyled>
      <Box sx={{ position: "absolute", top: "16px", right: "16px" }}>
        <DeleteButton onClick={handleDeleteClick} />
      </Box>
      <Box sx={{ width: "100%" }}>
        <Stack
          direction="row"
          spacing="16px"
          sx={{ px: "20px", py: "16px", width: "90%" }}
        >
          <Box>
            <Icon icon="document" size={24} color="#5B73E8" />
          </Box>
          <Typography
            color={"#5B73E8"}
            variant="body1"
            component={"a"}
            href={url}
            target="_blank"
            sx={{ textDecoration: "none" }}
          >
            {url?.substring(
              url?.lastIndexOf("_") + 1,
            )}
          </Typography>
        </Stack>
      </Box>
      <UIDialog
        className="ignore-onClickOutside"
        open={showDeleteConfirmModal}
        onClose={() => setShowDeleteConfirmModal(false)}
        sx={{ minWidth: "420px" }}
      >
        <UIDialog.Header component="div" sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="space-between">
            <NodeType text="Remove URL" icon="document" color="#FFB084" />
            <Box
              sx={{
                px: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton onClick={() => setShowDeleteConfirmModal(false)}>
                <Icon icon="close" size={16} />
              </IconButton>
            </Box>
          </Stack>
        </UIDialog.Header>
        <UIDialog.Body sx={{ px: "16px", pb: "8px", minWidth: "420px" }}>
          <Typography
            component="label"
            sx={{
              color: "#7E8392",
              fontSize: "12px",
              lineHeight: "18px",
              fontWeight: 400,
            }}
          >
            Are you sure you want to remove this document?
          </Typography>
        </UIDialog.Body>
        <UIDialog.Footer
          sx={{ justifyContent: "space-between", px: "16px", pb: "8px" }}
        >
          <UIButton
            variant="outlined"
            onClick={() => setShowDeleteConfirmModal(false)}
          >
            No
          </UIButton>
          <UIButton
            variant="contained"
            color="error"
            startIcon={<Icon icon="delete" size={16} />}
            onClick={handleConfirmDelete}
          >
            Yes
          </UIButton>
        </UIDialog.Footer>
      </UIDialog>
    </DocumentUploadedSectionStyled>
  );
};
export default Document;
