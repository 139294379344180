// vendors
import React, { Fragment, useEffect, useState } from "react";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

// components
import Icon from "../Icon";
import UIButton from "../Button";
import SearchInput from "../SearchInput";
import MainList from "./main.list";
import ChildList from "./child.list";
import Modal from "../Modal";
import { FlowBuilderService } from "../../../../Services/Apis/FlowBuilder";
import { useParams } from "react-router-dom";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";

// styles
import {
  NoResultFoundStyled,
  NoNodeExistStyled,
  ContainerStyled,
  ToolbarStyled,
  ListStyled,
} from "./drawer.styled";

// utils
import { getText } from "../../Utils";

const Drawer = ({
  flows,
  activeFlowID,
  onNewFlowClick,
  nodes,
  onDeleteFlow,
  onSelectItem,
  getFlows,
}: DrawerProps) => {
  const [open, setOpen] = useState<string>(activeFlowID);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [openModal, setOpenModal] = useState<string>("");
  const [openCopyModal, setOpenCopyModal] = useState<string>("");
  const { showAlert } = useNotification();
  // const [flows, setFlows] = useState([]);
  const { bot_id } = useParams();

  const handleClick = (id: string, order: number) => {
    setOpen((prevState: string) => {
      if (prevState !== id) {
        onSelectItem && onSelectItem(id, order);
        return id;
      } else {
        return prevState;
      }
    });
  };

  const getIndex = (qid: string) =>
    flows
      .map(
        (flow: { qid: string; name: string; nodeCounts: number }) => flow.qid
      )
      .indexOf(qid);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchTerm(event.target.value);
  };

  const handleDeleteClick = (id: string) => {
    setOpenModal(id);
  };

  //set flowid to open copy confirmation popup
  const handleCopyClick = (id: string) => {
    setOpenCopyModal(id);
  }
  const handleCopyFlow = async () => {
    if (openCopyModal) {
      try {
        await FlowBuilderService.copyFlow(openCopyModal, bot_id);
        showAlert("Flow copied successfully!", "success");
        if(getFlows){
          await getFlows();
        }
        
      } catch (error) {
        showAlert("Failed to copy the flow.", "error");
      } finally {
        setOpenCopyModal("");
      }
    }
  };

  const getValidNodes = (nodes: any) => {
    let validNodes = [];
    if (nodes && nodes.length > 0) {
      validNodes = nodes.filter((node: any) => {
        if (getText(node.response) !== "") {
          return node;
        }
      });
    }
    return validNodes;
  };

  const handlePrimaryButtonClick = () => {
    onDeleteFlow && onDeleteFlow(openModal);
    setOpenModal("");
  };

  const handleSecondaryButtonClick = () => {
    setOpenModal("");
    setOpenCopyModal("");
  };

  const handleModalClose = () => {
    setOpenModal("");
    setOpenCopyModal("");
  };

  useEffect(() => {
    if (flows && flows?.length > 0 && Array.isArray(flows)) {
      const results: any = flows?.filter((flow: any) =>
        flow?.name?.toLowerCase()?.includes(searchTerm.toLowerCase())
      );
      setSearchResults(results);
    }
  }, [searchTerm, flows]);

  useEffect(() => {
    setOpen(activeFlowID);
  }, [activeFlowID]);

  return (
    <ContainerStyled>
      <ToolbarStyled>
        <Stack
          sx={{ width: "100%" }}
          flexDirection="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography variant="subtitle1" color="default.black">
            Flows
          </Typography>
          <UIButton
            variant="outlined"
            size="medium"
            startIcon={
              <Icon icon="add" disableFill color="default.blue" size={10} />
            }
            onClick={onNewFlowClick}
          >
            New Flow
          </UIButton>
          <IconButton sx={{ ml: "16px", display: "none" }}>
            <Icon icon="settings" size={24} />
          </IconButton>
        </Stack>
      </ToolbarStyled>
      <Divider />
      <ListItem sx={{ p: "16px" }}>
        <SearchInput
          placeholder="Search"
          size="small"
          onChange={handleSearchInputChange}
        />
      </ListItem>
      <ListStyled>
        {searchResults.length > 0 ? (
          searchResults.map((flow: Flow, index: any) => {
            const openChild = open === flow.qid;
            const validNodes = getValidNodes(nodes);
            return (
              <Fragment key={`${flow.qid}-${flow.name}`}>
                <MainList
                  onItemClick={() => handleClick(flow.qid, index + 1)}
                  onDeleteClick={() => handleDeleteClick(flow.qid)}
                  flow={flow}
                  order={getIndex(flow.qid) + 1}
                  openChild={openChild}
                  handleCopyClick={() => handleCopyClick(flow.qid)}
                />
                <Collapse in={openChild} timeout="auto" unmountOnExit>
                  {validNodes.length > 0 ? (
                    <ChildList childItems={validNodes} flowOrder={index + 1} />
                  ) : (
                    <NoNodeExistStyled>No Nodes Exist</NoNodeExistStyled>
                  )}
                </Collapse>
              </Fragment>
            );
          })
        ) : (
          <NoResultFoundStyled variant="subtitle1">
            No Matching flow found!
          </NoResultFoundStyled>
        )}
      </ListStyled>
      <Modal
        open={openModal !== ""}
        type={"default"}
        size={"xs"}
        onClose={handleModalClose}
        primaryButtonConfig={{
          label: "Yes",
          onClick: handlePrimaryButtonClick,
        }}
        secondaryButtonConfig={{
          label: "Cancel",
          onClick: handleSecondaryButtonClick,
        }}
        header={{ title: "Confirm" }}
      >
        <Typography variant="body2" sx={{ pb: "4px" }}>
          Are you sure do want to delete this flow?
        </Typography>
      </Modal>
      {/* copy confirmation modal */}
      <Modal
        open={openCopyModal != ""}
        type={"default"}
        size={"xs"}
        onClose={handleModalClose}
        primaryButtonConfig={{
          label: "Yes",
          onClick: handleCopyFlow,
        }}
        secondaryButtonConfig={{
          label: "Cancel",
          onClick: handleSecondaryButtonClick
        }}
        header={{ title: "Copy Flow" }}
      >
        <Typography variant="body2" sx={{ pb: "4px" }}>Are you sure to copy this flow?</Typography>
      </Modal>
    </ContainerStyled>
  );
};

export default Drawer;
