import { alpha } from "@material-ui/core";
import {
  Box,
  Divider,
  FormControlLabel,
  FormHelperText,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuProps,
  Switch,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  HocBackdropLoader,
  HocBackdropLoaderProps,
} from "../HOC/HocBackdropLoader";
import { useNotification } from "../Hooks/useNotification/useNotification";
import { ChatbotConsoleService } from "../Services/Apis/ChatbotConsoleService";
import { CoreService } from "../Services/Apis/CoreApis";
import { useAppDispatch, useAppSelector } from "../Store";
import {
  logoutAuth,
  refreshAccessToken,
  useAuthStore,
} from "../Store/Slices/AuthSlice";
import {
  setIsUserAvailable,
  useCurrentUser,
} from "../Store/Slices/CurrentUser/CurrentUserSlice";
import { useCurrentBot } from "../Store/Slices/DashboardSlices";
import { socketStatusStore } from "../Store/Slices/socket/SocketSlice";
import { formatString } from "../Utils";
import AppIcon from "./AppIcon";
import { LiveChatActions } from "../Store/Slices/socket/liveChat/chat.actions";

import * as MUI from "@mui/material";
import { useSocket } from "../socket/hooks/socket-context";
import { logout } from "../Store/logout";

interface Props extends HocBackdropLoaderProps {
  anchorEl: HTMLElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  open: boolean;
  data?: any;
}

const AppUserDropdown: React.FC<Props> = ({
  anchorEl,
  setAnchorEl,
  open,
  data,
  setLoading,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentBot = useAppSelector(useCurrentBot);
  const { data: authData } = useAppSelector(useAuthStore);
  const { isUserAvailable } = useAppSelector(useCurrentUser);
  const { socket } = useSocket();
  const { showAlert } = useNotification();
  const HEADERS = {
    appid: JSON.stringify({
      botid: currentBot.bot_id,
    }),
  };


  const handleClick = (curItem: any) => {
    setAnchorEl(null);
    if (curItem?.name && curItem?.name === "Logout") {
      let data = {
        user_id: authData?.data?.user?.id,
        workspace_id: authData?.data?.user?.workspace_id,
      };
      setLoading(true)
      CoreService.userLogout(data).finally(() => {
        navigate("/login", { replace: true });
        const appVersion = window.localStorage.getItem("app_version");
        dispatch(logoutAuth(null));
        dispatch(logout()); // Dispatch the logout action
        window.localStorage.clear();
        if (appVersion) {
          window.localStorage.setItem("app_version", appVersion);
        }
        return;
      });
    }

    let URL = formatString(curItem?.url, [currentBot?.bot_id]);
    navigate(URL);
  };

  return (
    <StyledMenu
      id="demo-customized-menu"
      MenuListProps={{
        "aria-labelledby": "demo-customized-button",
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={() => setAnchorEl(null)}
      style={{
        maxWidth: "276px",
        zIndex: 99,
      }}
    >
      <MyAccount>
        <Box
          sx={{ padding: "9px 16px" }}
          display="flex"
          justifyContent="flexStart"
          alignItems="center"
          className="myAccount"
        >
          <div className="myAccountText">MY ACCOUNT</div>
        </Box>
      </MyAccount>

      {/* <Divider /> */}
      {/* <div
        style={{
          marginTop: "0.5rem",
          padding: "8px 1rem",
          overflow: "hidden",
        }}
      >
        <FormControlLabel
          control={
            <CustomSwitch
              checked={isUserAvailable}
              onChange={handleChange}
              name="userOnline"
              size="small"
            />
          }
          label={isUserAvailable ? "Online" : "Away"}
        />
        <FormHelperText>
          {isUserAvailable ? "You're now Online" : "You're now offline."}
        </FormHelperText>
      </div> */}
      
      {data &&
        data.length > 0 &&
        data.map((curElem: any, index: number) => {
          return (
            <MenuItem
              key={index}
              onClick={() => handleClick(curElem)}
              disableRipple
              sx={{
                height: "40px",
                borderTop:
                  curElem?.name === "Logout"
                    ? "1px solid rgba(0, 0, 0, 0.12)"
                    : "none",
                padding: curElem?.name === "Logout" ? "9px 16px" : "6px 16px",
                color: "#21233c",
              }}
            >
              <ListItemIcon>
                <AppIcon size="20px" color={"#21233c"} title={curElem.avatar} />
              </ListItemIcon>
              <p style={{ fontSize: "14px" }}>{curElem.name}</p>
            </MenuItem>
          );
        })}
    </StyledMenu>
  );
};

export default HocBackdropLoader(AppUserDropdown);

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    sx={{ marginTop: "0.6rem" }}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    border: "1px solid #e5e9f2",
    boxShadow: "0 3px 12px 1px rgba(44,55,130,.15)",
    borderRadius: "0 0 4px 4px",
    marginTop: theme.spacing(1),
    right: 6,
    minWidth: 276,
    maxWidth: 300,
    color: "rgb(55, 65, 81)",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiListItemText-primary": {
      fontFamily: theme.typography.fontFamily,
      fontSize: "1rem",
      fontWeight: 500,
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 16,
        color: theme.palette.secondary,
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.secondary.main
        ),
      },
    },
  },

  "& .MuiSwitch-root": {
    marginRight: "0.6rem",
  },
  "& .MuiFormControlLabel-root": {
    marginLeft: "-4px",
  },
}));

const MyAccount = styled(Box)`
  & {
    padding-bottom: 4px;
    .myAccount {
      padding-bottom: 8px;
      .myAccountText {
        color: #65686f;
        text-overflow: ellipsis;
        text-transform: uppercase;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 500;
      }
      .preferences {
        color: #21232c;
        font-size: 14px;
      }
    }
  }
`;

const CustomSwitch = MUI.styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",

  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 4,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#FFFFFF",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#5B73E8;",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 8,
    height: 8,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
