import { useEffect, useLayoutEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { CoreService } from "../Services/Apis/CoreApis";
import { ChangeLayout, GETA_LAYOUT, useAppSelector, useLayoutStore } from "../Store";
import {
  logoutAuth,
  setConfigData,
  setLoadingState,
  useAuthStore,
} from "../Store/Slices/AuthSlice";
import { useNotification } from "./useNotification/useNotification";
import { setIsUserAvailable, useCurrentUser } from "../Store/Slices/CurrentUser/CurrentUserSlice";

const redirectToLoginWithNext = (navigate: any, next?: any) => {
  if (!next) {
    next = window.location.href;
  }
  navigate(`/login?next=${next}`);
};

const useAuthentication = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();
  let { showAlert } = useNotification();
  const { data: authData } = useAppSelector(useAuthStore);
  const { isUserAvailable } = useAppSelector(useCurrentUser);
  const { activeLayout } = useSelector(useLayoutStore);
  const [searchParams] = useSearchParams();
  const next = searchParams.get("next");

  const handleLogout = () =>{
    let data = {
      user_id: authData?.data?.user?.id,
      workspace_id: authData?.data?.user?.workspace_id,
    };

    CoreService.userLogout(data).finally(() => {
      dispatch(logoutAuth(null));
      navigate("/login",{replace:true});
      return;
    });
  }

  const reloadLoginFn = () => {
    showAlert(
      "Session verification failed. Please try logging in again.",
      "error"
    );
    const appVersion = window.localStorage.getItem("app_version");
    window.localStorage.clear();
    if (appVersion) {
      window.localStorage.setItem("app_version", appVersion);
    }
    dispatch(logoutAuth(null));
    handleLogout()
    setTimeout(() => {
      redirectToLoginWithNext(navigate, next);
    });
  };

  useLayoutEffect(() => {
    if (authData && authData?.data && location?.pathname !== "/login") {
      dispatch(setLoadingState(true));
      CoreService.tokenVerify()
        .then((res) => {
          if (res.data) {
            if (!res.data.status) {
              setTimeout(() => {
                handleLogout()
                dispatch(logoutAuth(null));
                redirectToLoginWithNext(navigate, next);
              });
            } else {
              dispatch(setConfigData({ payload: res.data.token }));
            }
          }
        })
        .catch((err: any) => {
          reloadLoginFn();
        }).finally(() => {
          dispatch(setLoadingState(false));
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleLayout = (layout: GETA_LAYOUT) => {
    if (layout === activeLayout) return;

    dispatch(ChangeLayout({ activeLayout: layout }));
  };

  const setUserOnlineStatus = (status: boolean) => { 
    if(status === isUserAvailable) return;
    dispatch(setIsUserAvailable(status));
  }

  useLayoutEffect(() => {
    if (authData && authData?.data) {
      if (!authData?.data?.user?.is_email_verified) {
        return navigate(`/verify`);
      }
      setUserOnlineStatus(authData?.data?.user?.onlinestatus)
      if (
        location.pathname.indexOf("/workspace") >= 0 ||
        location.pathname.indexOf("/bot") >= 0
      ) {
        toggleLayout(GETA_LAYOUT.CLIENT_LAYOUT);
      } else {
        toggleLayout(GETA_LAYOUT.BUILD_LAYOUT);
      }
    } else {
      toggleLayout(GETA_LAYOUT.WELCOME_LAYOUT);
    }
  }, [authData, location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps
  return useMemo(
    () => ({
      isUserLoggedIn: authData?.data?.user?.is_email_verified,
    }),
    [authData]
  );
};

export default useAuthentication;
