// vendors
import { useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";

// components
import Drawer from "./Components/Drawer";
import NodeBuilder from "./Core/NodeBuilder";

// hooks
import useDocumentTitle from "../../Hooks/useDocumentTitle";
import { useCurrentBot } from "../../Store/Slices/DashboardSlices";
import { useAppDispatch } from "../../Store";

// theme
import theme from "./Theme";
import "./override.scss";

// slices
import {
  useFlows,
  useNodes,
  setActiveFlow,
  setBotId,
  getRightDrawerStatus,
} from "./Slices/main.slice";
import {
  fetchFlows,
  fetchFlow,
  createFlow,
  deleteFlow,
} from "./Slices/main.service";

// styled
import {
  ContainerStyled,
  RightContainerStyled,
  RightContentWrapperStyled,
  NodesWrapperStyled,
} from "./flowBuilder.styled";
import { useParams } from "react-router-dom";
import { ContactsActions } from "../../Store/Slices/Contacts/contacts.action";
import LimitExceedModal from "../../CommonComponents/LimitExceededModal";
import useModal from "../../Hooks/useModel/useModel";
import RightDrawer from "./Components/RightDrawer";
const FlowBuilder = () => {
  useDocumentTitle("Flow Builder");
  const { bot_id }: any = useParams();
  const currentBot = useSelector(useCurrentBot);
  const flows = useSelector(useFlows);
  const nodes = useSelector(useNodes);
  const rightDrawer = useSelector(getRightDrawerStatus);
  const dispatch = useAppDispatch();
  const botId = { botid: bot_id || currentBot.bot_id };
  const initialFlowId = flows.length > 0 ? flows[0].qid : null;
  useEffect(() => {
    dispatch(setBotId(botId));
    dispatch(fetchFlows({ botId }));
    initialFlowId !== null &&
      dispatch(fetchFlow({ botId, flowId: initialFlowId }));
    dispatch(ContactsActions.getContactsSelectDataAction({ data: "" }));
  }, []);

  useEffect(() => {
    if (initialFlowId !== null) {
      dispatch(setActiveFlow(initialFlowId));
      dispatch(fetchFlow({ botId, flowId: initialFlowId }));
    }
  }, [initialFlowId]);

  const handleNewFlowClick = () => {
    dispatch(createFlow({ botId }));
  };

  const handleDeleteFlow = (qid: string) => {
    dispatch(deleteFlow({ botId, qid }));
  };

  const handleFlowSelect = (qid: string, displayOrder: number) => {
    dispatch(setActiveFlow(qid));
    dispatch(fetchFlow({ botId, flowId: qid }));
  };
  const getFlows = () => {
    dispatch(fetchFlows({ botId }));
  };
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ContainerStyled>
        {!rightDrawer?.isOpen && (
          <Drawer
            nodes={nodes}
            flows={flows}
            activeFlowID={initialFlowId}
            onNewFlowClick={handleNewFlowClick}
            onDeleteFlow={handleDeleteFlow}
            onSelectItem={handleFlowSelect}
            getFlows={getFlows}
          />
        )}
        <RightContainerStyled id="flow-builder-right-section">
          <RightContentWrapperStyled>
            <NodesWrapperStyled>{nodes && <NodeBuilder />}</NodesWrapperStyled>
          </RightContentWrapperStyled>
        </RightContainerStyled>
        <RightDrawer
          isOpen={rightDrawer?.isOpen}
          componentType={rightDrawer?.componentType}
        />
      </ContainerStyled>
    </ThemeProvider>
  );
};

export default FlowBuilder;
