import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  AppForm,
  AppFormField,
  AppSubmitButton,
} from "../../AppComponents/Forms";
import AppFormCheckboxField from "../../AppComponents/Forms/AppFormCheckboxField";
import AppFormPasswordField from "../../AppComponents/Forms/AppFormPasswordField";
import { LoaderComponentProps, withLoader } from "../../AppComponents/Loader";
import {
  BrandBox,
  DetailsBox,
  ErrorTextMsg,
  ExtraBox,
  LeftSide,
  LinkText,
  LoginPageWrap,
  LogoText,
  RightSide,
  TitleText,
} from "../../Customization/CommonElements";
import { theme } from "../../Customization/Theme";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { GETA_MODULES, UserBusiness } from "../../Models";
import { loginSchema } from "../../Models/ValidationSchema";
import { CoreService } from "../../Services/Apis/CoreApis";
import {
  getLoginFn,
  logoutAuth,
  setConfigData,
  setLoadingState,
} from "../../Store/Slices/AuthSlice";
import loginIllustration from "../../assets/images/loginIllustration.svg";
import logo from "../../assets/images/logo.svg";
import BusinessSelectionDialog from "./BusinessSelectionDialog";

const redirectToLoginWithNext = (navigate: any, next?: any) => {
  if (!next) {
    next = window.location.href;
  }
  navigate(`/login?next=${next}`);
};

interface LoginProps extends LoaderComponentProps {}

const LoginPage = (props: LoginProps) => {
  let navigate = useNavigate();
  let { showAlert } = useNotification();
  let formRef = useRef<any>();
  const [searchParams] = useSearchParams();
  const next = searchParams.get("next");
  const sso = searchParams.get("sso");

  useEffect(() => {
    props.setLoading(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [businessSelection] = useState<boolean>(false);
  const [userBusinesses] = useState<UserBusiness[]>([]);

  const [invalidMessage, setInvalidMessage] = useState<string>("");

  const dispatch = useDispatch();

  const [userInput, setUserInput] = useState<any>({
    email: "",
    password: "",
    checked: false,
  });

  const reloadLoginFn = () => {
    showAlert(
      "Session verification failed. Please try logging in again.",
      "error",
    );
    const appVersion = window.localStorage.getItem("app_version");
    window.localStorage.clear();
    if (appVersion) {
      window.localStorage.setItem("app_version", appVersion);
    }
    dispatch(logoutAuth(null));
    setTimeout(() => {
      redirectToLoginWithNext(navigate, next);
    });
  };

  const redirectToHomepage = (data: any) => {
    dispatch(setConfigData({ payload: data }));
    showAlert("Login Successful!", "success");
    navigate("/", { replace: true });
  };

  const verifyTokenFn = () => {
    props.setLoading(true);
    dispatch(setLoadingState(true));
    CoreService.tokenVerify()
      .then((res: any) => {
        if (res.data) {
          if (!res.data.status) {
            reloadLoginFn();
          } else {
            redirectToHomepage(res.data.token);
          }
        }
      })
      .catch((err: any) => {
        reloadLoginFn();
      })
      .finally(() => {
        props.setLoading(false);
        dispatch(setLoadingState(false));
      });
  };

  const loginUser = (values: any, SubmitProps: any) => {
    props.setLoading(true);
    getLoginFn(dispatch)({
      username: values.email.trim().toLowerCase(),
      password: values.password.trim(),
      selected_business: values.selected_business,
      module_id: GETA_MODULES.CHATBOT_MODULE_ID,
      recaptcha_token: null,
      source: "geta",
      sso: sso,
    }).then(
      (data: any) => {
        let responseAuth = data?.token?.data;
        let workspace_id = responseAuth?.user?.workspace_id;
        const isEmailVerified = responseAuth?.user?.is_email_verified ?? false;

        setInvalidMessage("");
        // props.setLoading(false);
        SubmitProps?.setSubmitting(false);
        SubmitProps?.resetForm();
        if (workspace_id && isEmailVerified) {
          verifyTokenFn();
        } else {
          reloadLoginFn();
        }
      },
      (err: any) => {
        SubmitProps?.setSubmitting(false);
        SubmitProps?.resetForm();
        props.setLoading(false);
        showAlert(err.response?.data || "Something went wrong", "error");
      },
    );
  };

  const onBusinessSelect = (selectedBusiness: UserBusiness) => {
    // loginUser(values, SubmitProps);
    if (formRef) {
      formRef.current?.setFieldValue(
        "selected_business",
        selectedBusiness.business.id,
      );
      formRef.current?.handleSubmit();
    }
  };

  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    localStorage.removeItem("has_low_balance");
    loginUser(values, SubmitProps);
  };

  const redirectSignup = () => {
    navigate("/signup");
  };
  const redirectResetPassword = () => {
    navigate("/reset-password");
  };

  return (
    <LoginPageWrap>
      <LeftSide>
        <div>
          <img src={loginIllustration} alt="" />
        </div>
      </LeftSide>

      <RightSide
        style={{
          marginTop: "-1.5rem",
          alignItems: "center",
        }}
      >
        <DetailsBox>
          {!businessSelection && (
            <>
              <BrandBox>
                <img src={logo} alt="" />
                <LogoText>Geta</LogoText>
              </BrandBox>
              <TitleText>
                {businessSelection ? "Select Account" : "Login"}
              </TitleText>
              <ErrorTextMsg>{invalidMessage}</ErrorTextMsg>
            </>
          )}
          <AppForm
            initialValues={userInput}
            onSubmit={(values: any, submitProps: any) => {
              handleSubmitBtn(values, submitProps);
              setUserInput(values);
            }}
            validationSchema={loginSchema}
            innerRef={formRef}
          >
            {businessSelection && (
              <BusinessSelectionDialog
                onBusinessSelect={(ub) => onBusinessSelect(ub)}
                showDialog={businessSelection}
                userBusinesses={userBusinesses}
              />
            )}
            {!businessSelection && (
              <>
                <AppFormField
                  label="Email"
                  name="email"
                  placeholder="example@domain.com"
                  toLowerCase={true}
                />
                <AppFormPasswordField
                  label="Password"
                  name="password"
                  placeholder="Xpi9B*Ne8G6@"
                />

                <ExtraBox>
                  <AppFormCheckboxField label={"Remember Me"} name="checked" />
                  <LinkText
                    variant="subtitle2"
                    color={theme.palette.primary.main}
                    onClick={() => redirectResetPassword()}
                  >
                    <span> Forgot Password?</span>
                  </LinkText>
                </ExtraBox>

                <AppSubmitButton
                  variant="cta"
                  style={{ width: "100%" }}
                  title={"login"}
                />
              </>
            )}
          </AppForm>
          {!businessSelection && (
            <LinkText
              sx={{ marginTop: "1rem" }}
              variant="subtitle2"
              color={theme.palette.primary.main}
            >
              Don't have an account?{" "}
              <span onClick={() => redirectSignup()}> Signup</span>
            </LinkText>
          )}

          <LinkText
            sx={{ marginTop: "0.5rem", fontWeight: "400 !important" }}
            variant="subtitle2"
            color={theme.palette.primary.main}
          >
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy">
              {" "}
              Privacy Policy{" "}
            </a>{" "}
            and
            <a href="https://policies.google.com/terms"> TOS </a> apply.
          </LinkText>
        </DetailsBox>
      </RightSide>
    </LoginPageWrap>
  );
};

export default withLoader(LoginPage);
