// vendors
import { useState, useEffect, useContext } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { useSelector } from "react-redux";

// slices
import { getBotId, getActiveFlow, setUnSavedNodeId } from "../../../../Slices/main.slice";
import { createNode, fetchFlow, fetchFlows } from "../../../../Slices/main.service";

// components
import UIButton from "../../../../Components/Button";
import Icon from "../../../../Components/Icon";

// hooks
import { useFetchFlowById, useAddNewNode } from "./../../../../Hooks";
import { getText } from "../../../../Utils";
import { useAppDispatch } from "../../../../../../Store";
import OptionsPopover from "../../../../Core/OptionsPopover";
import { v4 as uuid } from "uuid";
import { FlowBuilderService } from "../../../../../../Services/Apis/FlowBuilder";

interface Props {
  activeFlowId: string;
  flowOrder: number;
  onNodeSelect: (qId: string, nodeId: string | null, order: number | null) => void;
  selectedNode?: string;
  connectedFlow: any;
}

const EmptyState = () => (
  <Stack direction="column" alignItems="center" sx={{ py: "16px" }}>
    <Icon icon="exclamation-triangle" size={64} color="#7E8392" />
    <Typography
      fontSize={"12px"}
      lineHeight="18px"
      fontWeight={400}
      color="#7E8392"
    >
      No existing nodes
    </Typography>
  </Stack>
);

function strip(html: string) {
  let doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
}

const DisplayNodes = (props: Props) => {
  const { onNodeSelect, flowOrder, activeFlowId, selectedNode, connectedFlow } =
    props;
  const [selectedNodeId, setSelectedNode] = useState<string>("");
  const [nodes, setNodes] = useState<Array<any> | null>(null);
  const { data, loading, getNodes } = useFetchFlowById(activeFlowId);
  const { addNode } = useAddNewNode();
  const dispatch = useAppDispatch();
  const botId = useSelector(getBotId);
  const activeFlow = useSelector(getActiveFlow);
  const [showOptions, setShowOptions] = useState<number | null>(null);
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  useEffect(() => {
    setNodes(data);
  }, [data,activeFlowId]);

  const onClickNewNode = (event: React.MouseEvent<HTMLElement>, order: any,) => {
    setOpenPopover(event.currentTarget);
    setShowOptions(parseInt(order));
  }

  const dynamicResponses = (subTypeText: any) => {
    switch(subTypeText){
      case "interactive-message":
        return [{interactive: {
          type: "interactive-message",
          body: "Interactive Message",
          header: { type: "text", text: "" },
        }}]
      case "text":
        return [{
          type: "text",
          value: "Enter Your Message"
        }]
      case "interactive-message-list":
        return [{
          interactive: {
            type: "interactive-message-list",
            body: "Interactive Message List",
            header: { type: "text", text: "" },
          }
        }]
      case "flow-list":
        return [{
          interactive: {
            type: "flow-list",
            body: "Whatsapp Flow",
            header: { type: "text", text: "" },
          }
        }]
      case "img-file":
        return [{
          type: "text",
          value: "Image or File"
        }]
      case "media" : 
        return null
      default:
        return [{
          type: "text",
          value: `Enter your ${subTypeText}`
        }]
    }
  }

  const handleOptionSelect = (option: any) => {
    if (showOptions !== null) {
      if (nodes !== null && nodes.length > 0 ) {
        if (option?.type) {
          const nodeId = uuid();
          const payload = {
            id: nodeId,
            qid: activeFlowId,
            stage: `${flowOrder}.${data?.length + 1}`,
            displayOrder: nodes.length,
            subType: option.type,
            isInBetween: showOptions <= nodes.length,
            response: dynamicResponses(option?.type)
          };
          FlowBuilderService.createNode(payload, {
            appid: JSON.stringify(botId),
          }).then((response: any) => {
            if (response?.data?.status === 200) {
              dispatch(fetchFlows({ botId }));
              if (activeFlow.qid === activeFlowId) {
                dispatch(fetchFlow({ botId, flowId: activeFlowId }));
              }
              getNodes(activeFlowId).then((response: any) => {
                setNodes(response);
              });
            }
          });
        }
      } else {
        const nodeId = uuid();
        const payloadData = {
          id: nodeId,
          qid: activeFlowId,
          stage: `${flowOrder}.1`,
          displayOrder: 0,
          subType: option?.type,
          response: dynamicResponses(option?.type),
        };
        FlowBuilderService.createNode(payloadData, {
          appid: JSON.stringify(botId),
        }).then((response: any) => {
          if (response?.data?.status === 200) {
            getNodes(activeFlowId).then((response: any) => {
              setNodes(response);
            });
          }
        });
      }
      setOpenPopover(null);
      setShowOptions(null);
    }
  };

  const onNodeClick = (node: any, order: number) => {
    if (selectedNode === node.nodeId) {
      onNodeSelect && onNodeSelect(node.qid, null, null);
      return;
    }

    setSelectedNode(node.nodeId);
    onNodeSelect && onNodeSelect(node.qid, node.nodeId, order);
  };

  const handlePopoverClose = () => {
    setOpenPopover(null);
  }

  const getNodeIdentifier: any = () => `${flowOrder}.${data?.length + 1 || 1}`;
  return (
    <Box sx={{ p: "16px" }}>
      <UIButton
        variant="outlined"
        fullWidth
        startIcon={<Icon icon="add" size={16} />}
        onClick={(event) => {
          onClickNewNode(event, `${flowOrder}.${data?.length + 1}`)
        }}
      >
        New Node
      </UIButton>
      <OptionsPopover
        open={openPopover}
        onClose={handlePopoverClose}
        onOptionSelect={(option) => handleOptionSelect(option)}
      />
      <Box sx={{ maxHeight: "200px", overflowY: "auto", mt: "12px" }}>
        {loading ? (
          "fetching nodes"
        ) : nodes === null ? (
          <EmptyState />
        ) : (
          <List
            sx={{
              width: "100%",
              maxWidth: 360,
              bgcolor: "background.paper",
            }}
            component="nav"
          >
            {nodes.map((node: any, index: number) => {
              const text = strip(
                getText(node.response)?.replace(/[\n\t\r]/g, "")
              );
              const active = connectedFlow?.nodeId === node?.nodeId;
              return (
                <ListItem
                  disablePadding
                  sx={{
                    "&:hover": { backgroundColor: "#F5F6F8" },
                  }}
                  key={`list-item-${node.nodeId}`}
                >
                  <ListItemButton
                    sx={{
                      fontSize: "14px",
                      lineHeight: "21px",
                      fontWeight: 400,
                      color: "#7E8392",
                      "&:hover": { backgroundColor: "transparent" },
                      ...(active && { backgroundColor: "#F5F6F8" }),
                    }}
                    onClick={() => onNodeClick(node, index + 1)}
                  >
                    <Typography
                      fontWeight={400}
                      fontSize={14}
                      lineHeight="21px"
                      color="#7E8392"
                    >
                      {`${props.flowOrder}.${index + 1} `}
                      {text.length > 40 ? `${text.slice(0, 30)}...` : text}
                    </Typography>
                    {active && (
                      <Box sx={{ marginLeft: "auto" }}>
                        <Icon icon="done" size={14} color="#2ECC71" />
                      </Box>
                    )}
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        )}
      </Box>
    </Box>
  );
};
export default DisplayNodes;
