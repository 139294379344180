// vendors
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// components
import Input from "../../Components/Input";
import UIModal from "../../Components/Modal";
import { isApiVariable } from "../../Utils/editor";

type Props = {
  url: string;
  open: boolean;
  onClose: () => void;
  onSave: (imgURL: string) => void;
};

const ImgModal = (props: Props) => {
  const [imgURL, setImgURL] = useState<string>(props.url || "");

  const validateImgURL = (url: string) => {
    const isVariable =  isApiVariable(url)
    if(isVariable){
      return isVariable
    }
    const regex = /^https?:\/\/.*\/.*\.(png|gif|webp|jpeg|jpg)\??.*$/gim;
    return regex.test(url);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // event?.stopPropagation();
    setImgURL(event.target.value);
  };

  useEffect(() => {
    setImgURL(props.url);
  }, [props.url]);

  return (
    <UIModal
      open={props.open}
      type={"default"}
      size={"sm"}
      onClose={props.onClose}
      primaryButtonConfig={{
        label: "Save",
        onClick: () => props.onSave(imgURL),
      }}
      secondaryButtonConfig={{
        label: "Cancel",
        onClick: () => props.onClose(),
      }}
      header={{ title: "Image URL", color: "#84CBFF", icon: "image" }}
    >
      <Box sx={{ py: "8px !important" }}>
        <Typography
          component="label"
          sx={{
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "18px",
            color: "#7E8392",
            pb: "4px",
          }}
        >
          URL
        </Typography>
        <Input
          placeholder="link"
          id="image-link-input"
          size="small"
          name="url"
          onChange={onChange}
          value={imgURL}
          error={imgURL !== "" && !validateImgURL(imgURL)}
          fullWidth
        />
      </Box>
    </UIModal>
  );
};
export default ImgModal;
