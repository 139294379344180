import { useState, useEffect } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePickerStyled } from "./date.styled";
import add from "date-fns/add";
import sub from "date-fns/sub";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import parseISO from "date-fns/parseISO";
import { parse, format } from 'date-fns';

import Icon from "../../Components/Icon";
import Input from "../../Components/Input";
import UISelect from "./select";

import {
  TextStyled,
  FormLabelWrapperStyled,
  DropdownLabelStyled,
  DateFormatWrapperStyled,
  DateFormatLabel,
  SectionContainerStyled,
  SectionWrapperStyled,
  ErrorTextStyled,
} from "./date.styled";

import { DateSelectProps } from "./types";
import {
  //supportedDateFormats,
  dateTypeOptions,
  dateUnitOptions,
} from "./utils";

const DateRangeConfig = (props: DateSelectProps) => {
  const { onChange, selectedDates } = props;

  const parseDateString = (dateStr: string | Date) => {
    if (!dateStr) return new Date();
    if (dateStr instanceof Date) return dateStr;
    try {
      return parse(dateStr, 'dd/MM/yyyy', new Date());
    } catch (error) {
      return new Date();
    }
  };

  const [fromDate, setFromDate] = useState(
    selectedDates.from ? {
      ...selectedDates.from,
      date: selectedDates.from.type === "CUSTOM" ? parseDateString(selectedDates.from.date) : new Date()
    } : {
      type: "LAST",
      date: new Date(),
      number: 1,
      unit: "days",
    }
  );

  const [toDate, setToDate] = useState(
    selectedDates.to ? {
      ...selectedDates.to,
      date: selectedDates.to.type === "CUSTOM" ? parseDateString(selectedDates.to.date) : new Date()
    } : {
      type: "NEXT",
      date: new Date(),
      number: 1,
      unit: "days",
    }
  );

  const [dateFormat, setDateFormat] = useState("dd/mm/yyyy");
  const [dateRange, setDateRange] = useState(false || selectedDates.dateRange);

  const [error, setError] = useState("");

  useEffect(() => {
    validateDates();
  }, [fromDate]);

  useEffect(() => {
    validateDates();
  }, [toDate]);

  const handleFromDateTypeChange = (event: any) => {
    setFromDate({ ...fromDate, type: event.target.value });
  };

  const handleFromDateChange = (date: any) => {
    setFromDate({ ...fromDate, date: date || new Date() });
    onChange({
      from: { ...fromDate, date: date || new Date() },
      to: toDate,
      error: error !== "",
      dateFormat,
      dateRange: dateRange,
    });
  };

  const handleFromNumberChange = (event: any) => {
    setFromDate({ ...fromDate, number: parseInt(event.target.value) });
  };

  const handleFromDateUnitChange = (event: any) => {
    setFromDate({ ...fromDate, unit: event.target.value });
  };

  const handleToDateTypeChange = (event: any) => {
    setToDate({ ...toDate, type: event.target.value });
  };

  const handleToDateChange = (date: any) => {
    setToDate({ ...toDate, date: date || new Date() });
    onChange({
      from: fromDate,
      to: { ...toDate, date: date || new Date() },
      error: error !== "",
      dateFormat,
      dateRange: dateRange,
    });
  };

  const handleToNumberChange = (event: any) => {
    setToDate({ ...toDate, number: parseInt(event.target.value) });
  };

  const handleToDateUnitChange = (event: any) => {
    setToDate({ ...toDate, unit: event.target.value });
  };

  const handleDateFormatChange = (event: any) => {
    setDateFormat(event.target.value);
    onChange({
      from: fromDate,
      to: toDate,
      error: error !== "",
      dateFormat: event.target.value,
      dateRange: dateRange,
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateRange(event.target.checked);
    onChange({
      from: fromDate,
      to: toDate,
      error: error !== "",
      dateFormat: dateFormat,
      dateRange: event.target.checked,
    });
  };

  const validateDates = () => {
    const from = fromDate.type === "CURRENT" ? new Date() : getFromDate();
    const to = toDate.type === "CURRENT" ? new Date() : getToDate();
    
    if (from !== null && to !== null) {
      if (from?.getTime() > to?.getTime()) {
        setError("The 'from' date cannot be after the 'to' date");
        onChange({
          from: fromDate,
          to: toDate,
          error: true,
          dateFormat,
          dateRange: dateRange,
        });
        return false;
      } else if (from?.getTime() === to?.getTime()) {
        setError("From date and to date cannot be same");
        onChange({
          from: fromDate,
          to: toDate,
          error: true,
          dateFormat,
          dateRange: dateRange,
        });
        return false;
      } else {
        setError("");
        onChange({
          from: fromDate,
          to: toDate,
          error: false,
          dateFormat,
          dateRange: dateRange,
        });
        return true;
      }
    }
    return true;
  };

  const getFromDate = () => {
    const { type, date, number, unit } = fromDate;
    switch (type) {
      case "LAST":
        return addTime(date, -1 * number, unit);
      case "NEXT":
        return addTime(date, number, unit);
      case "CURRENT":
        return date;
      case "CUSTOM":
        return parseDateString(date);
      default:
        return null;
    }
  };

  const getToDate = () => {
    const { type, date, number, unit } = toDate;
    switch (type) {
      case "LAST":
        return addTime(date, -1 * number, unit);
      case "NEXT":
        return addTime(date, number, unit);
      case "CURRENT":
        return date;
      case "CUSTOM":
        return parseDateString(date);
      default:
        return null;
    }
  };

  const addTime = (date: any, amount: number, unit: string) => {
    switch (unit) {
      case "days":
        if (amount > 0) return add(new Date(), { days: amount });
        else return sub(new Date(), { days: amount * -1 });
      case "months":
        if (amount > 0) return add(new Date(), { months: amount });
        else return sub(new Date(), { months: amount * -1 });
      case "years":
        if (amount > 0) return add(new Date(), { years: amount });
        else return sub(new Date(), { years: amount * -1 });
      default:
        return null;
    }
  };

  return (
    <>
      <SectionContainerStyled>
        <SectionWrapperStyled>
          <DropdownLabelStyled>From</DropdownLabelStyled>
          <UISelect
            value={fromDate.type}
            options={dateTypeOptions}
            onChange={handleFromDateTypeChange}
          />
          {fromDate.type === "CUSTOM" && (
            <Box mt={"10px"}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePickerStyled
                  value={fromDate.date}
                  onChange={handleFromDateChange}
                />
              </LocalizationProvider>
            </Box>
          )}
          {["LAST", "NEXT"].includes(fromDate.type) && (
            <Box display={"flex"} gap={"8px"} mt={"8px"}>
              {/*<Input
                type="number"
                value={fromDate.number}
                //onChange={handleFromNumberChange}
                onChange={(e) => {
                  const value = Number(e.target.value); 
                  if (value < 0) {
                    setError("Date cannot be negative.");
                    return;
                  } else {
                    setError("");
                    handleFromNumberChange(e);
                  }
                }}
              />*/}
              <Input
                type="number"
                value={fromDate.number}
                onChange={(e) => {
                  const value = Math.max(0, Number(e.target.value)); 
                  handleFromNumberChange({ target: { value } }); 
                }}
              />
              <UISelect
                value={fromDate.unit}
                options={dateUnitOptions}
                onChange={handleFromDateUnitChange}
              />
            </Box>
          )}
        </SectionWrapperStyled>
        <SectionWrapperStyled>
          <DropdownLabelStyled>To</DropdownLabelStyled>
          <UISelect
            value={toDate.type}
            options={dateTypeOptions}
            onChange={handleToDateTypeChange}
          />
          {toDate.type === "CUSTOM" && (
            <Box mt={"10px"}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePickerStyled
                  value={toDate.date}
                  onChange={handleToDateChange}
                />
              </LocalizationProvider>
            </Box>
          )}
          {["LAST", "NEXT"].includes(toDate.type) && (
            <Box display={"flex"} gap={"8px"} mt={"8px"}>
              {/*<Input
                type="number"
                value={toDate.number}
                //onChange={handleToNumberChange}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  if (value < 0) {
                    setError("Date cannot be negative.");
                    return;
                  } else {
                    setError("");
                    handleToNumberChange(e);
                  }
                }}
              />*/}
              <Input
                type="number"
                value={toDate.number}
                onChange={(e) => {
                  const value = Math.max(0, Number(e.target.value)); 
                  handleToNumberChange({ target: { value } }); 
                }}
              />
              <UISelect
                value={toDate.unit}
                options={dateUnitOptions}
                onChange={handleToDateUnitChange}
              />
            </Box>
          )}
        </SectionWrapperStyled>
        {error && <ErrorTextStyled>{error}</ErrorTextStyled>}
      </SectionContainerStyled>
      {/* Date ranfe commented my be required in feature */}
      {/* <FormControlLabel
        sx={{ marginLeft: "-6px" }}
        control={
          <Checkbox
            onChange={handleCheckboxChange}
            checked={dateRange}
            checkedIcon={<Icon icon="checked" size={16} />}
            icon={<Icon icon="checkbox-default" size={16} />}
          />
        }
        label={
          <FormLabelWrapperStyled component="span">
            <TextStyled component="span">Allow date range</TextStyled>
          </FormLabelWrapperStyled>
        }
      /> */}
      {/*<DateFormatWrapperStyled>
        <DateFormatLabel>Date format</DateFormatLabel>
        <UISelect
          value={dateFormat}
          options={supportedDateFormats}
          onChange={handleDateFormatChange}
          placeholder="Select Date Format"
        />
      </DateFormatWrapperStyled>*/}
    </>
  );
};
export default DateRangeConfig;
