// vendors
import { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import startOfDay from "date-fns/startOfDay";
import { PickersDay } from "@mui/x-date-pickers";

// styles
import { DatePickerWrapperStyled } from "./date.styled";

interface Props {
  disabledDays: number[];
  onChange: (dates: string[]) => void;
  dates: string[];
}

const UIDatePicker = (props: Props) => {
  const { disabledDays, onChange, dates } = props;
  const [dateArray, setDateArray] = useState<string[]>(dates || []);
  const [value, setValue] = useState<Date>(new Date());

  useEffect(() => {
    setDateArray(dates);
  }, [dates]);

  const disableDays = (date: any) => {
    const day = date.getDay();
    if (disabledDays.length > 0) {
      return disabledDays.includes(day);
    }

    return false;
  };

  const findIndexDate = (dates: string[], date: Date) => {
    const dateTime = date.getTime();
    return dates.findIndex((item) => new Date(item).getTime() === dateTime);
  };

  const isSameDay = (day: number) => {
    const array: string[] = [...dateArray];
    const isMatched = array.filter((date) => new Date(date).getDate() === day);
    return isMatched.length > 0;
  };

  const handleChange = (newValue: Date | null) => {
    if (newValue !== null) {
      const array: string[] = [...dateArray];
      const date = startOfDay(newValue);
      const index = findIndexDate(array, date);
      if (index >= 0) {
        array.splice(index, 1);
      } else {
        array.push(new Date(date).toISOString());
      }
      setDateArray(array);
      setValue(newValue);
      onChange(array);
    }
  };

  return (
    <DatePickerWrapperStyled>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StaticDatePicker
          value={value}
          displayStaticWrapperAs="desktop"
          defaultValue={new Date()}
          onChange={handleChange}
          shouldDisableDate={disableDays}
          slots={{
            day: (props) => {
              const isSelected =
                !props.outsideCurrentMonth && isSameDay(props.day.getDate());
              return <PickersDay {...props} selected={isSelected} />;
            },
          }}
        />
      </LocalizationProvider>
    </DatePickerWrapperStyled>
  );
};

export default UIDatePicker;
