// vendors
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { getActiveNode, getLanguage, updateModifiedData } from "../../../Slices/main.slice";
import { useCurrentBot } from "../../../../../Store/Slices/DashboardSlices";
import { cleanMediaDetails, getInitialEditiorText, getMediaDetails, getResponse, getText } from "../../../Utils";
import { useAppDispatch } from "../../../../../Store";
import { getChannelWiseText, getHTMLContent, getMediaDetailsForMediaNode, getPlainTextData } from "../../../Utils/editor";
import MessagePreview from "../../../Components/MessagePreview";
import Editor from "../../../Components/Editor";
import UploadSection from "../../../Components/Editor/upload";
import UISelect from "../../../Components/Select";

const headerOptions = [
  {
    value: "image",
    label: "Image",
  },
  {
    value: "video",
    label: "Video",
  },
  {
    value: "document",
    label: "Document",
  },
];
const MediaNode = (props: NodeProps) => {
  const { channel } = useSelector(useCurrentBot);
  const { node } = props;
  const language = useSelector(getLanguage);
  const activeNode = useSelector(getActiveNode);
  const dispatch = useAppDispatch();

  const response = getResponse(node, language);
  const text = response?.[0]?.value?.text;
  const [localMediaType, setLocalMediaType] = useState(node?.response?.[0]?.type || "image");
  let media = getMediaDetailsForMediaNode(response);
  const [editorState, setEditorState] = useState(
    getInitialEditiorText(text || ""),
  );

  const isActiveNode = activeNode === node.nodeId;
  
  const onEditorStateChange = (editorState: any, mediaInfo: any) => {
    setEditorState(editorState);
      if(cleanMediaDetails(mediaInfo)){
      dispatch(
        updateModifiedData([
          {
            type: localMediaType,
            value:{
              text: getChannelWiseText(getHTMLContent(editorState), channel),
              url: mediaInfo?.value?.url
            }
          },
        ]),
      );
    }
  };
  
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLocalMediaType(event.target.value);
  };

    return (
    <Box p={"8px 16px"}>
      {isActiveNode ? (
        <>
          <div style={{marginBottom: "7px"}}>
          <UISelect
            value={localMediaType}
            options={headerOptions}
            onChange={handleSelectChange}
            isDisabled={false}
          />
          </div>
        <Editor
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          error={getPlainTextData(editorState) === ""}
          mediaInfo={media}
          isMediaNode={true}
          mediaName = {localMediaType}
        />
        </>
      ) : (
        <MessagePreview text={text} mediaDetails={media} isMediaNode={true} />
      )}
    </Box>
  );
};
export default MediaNode;
