import { ThemeProvider } from "@mui/system";
import { theme } from "./Customization/Theme";
import RootNavigator from "./Routes/RootNavigator";
import { Provider } from "react-redux";
import CustomSnackbarProvider from "./Hooks/useNotification/CustomSnackbarProvider";
import store, { useAppSelector } from "./Store";
import { validateToken } from "./Utils/Utils";
import * as Sentry from "@sentry/react";
import useVersionCheck from "./Hooks/useVersionCheck";
import { useState, useEffect } from "react";
import AppButton from "./AppComponents/AppButton";
import { useAuthStore } from "./Store/Slices/AuthSlice";
import { AnimationStyles, ContentWrapper, IconCircle, Overlay, TextContainer, TitleText, VersionPopupContainer, VersionText } from "./Utils/VersionPopupStyles";
import AppModel from "./Hooks/useModel/AppModel";
validateToken();

if (window.location.origin === "https://app.geta.ai/") {
  Sentry.init({
    dsn: "https://c38cc9c1273082c611f3ec8baf54831b@o4507446172188672.ingest.us.sentry.io/4507447376019456",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    environment: process.env.REACT_APP_SENTRY_ENV,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost"],
    tracePropagationTargets: [process.env.REACT_APP_SENTRY_DOMAIN as string],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

interface AnimatedUpdateNotificationProps {
  isUpdateAvailable: any;
  currentVersion: any;
  latestVersion: any;
  updateApp: () => any;
  dismissPopup: any;
}

const AnimatedUpdateNotification: React.FC<AnimatedUpdateNotificationProps> = ({ isUpdateAvailable, currentVersion, latestVersion, updateApp, dismissPopup }) => {
  const [animationState, setAnimationState] = useState('hidden');
  const [dismissed, setDismissed] = useState(false); 
  const { userConfig } = useAppSelector(useAuthStore);

  useEffect(() => {
    if (isUpdateAvailable) {
      setAnimationState("entering");
      const timer = setTimeout(() => {
        setAnimationState("stable");
      }, 2000);
      return () => clearTimeout(timer);
    } else {
      setAnimationState("hidden");
    }
  }, [isUpdateAvailable]);
  
  // Hiding the version update popup when these conditions occurs......
  if (dismissed || !isUpdateAvailable || userConfig === null) return null;

  return (
    <AppModel isShowing={isUpdateAvailable} onClose={dismissPopup}>
      <Overlay animationState={animationState} />
      <VersionPopupContainer animationState={animationState}>
        <ContentWrapper>
          <IconCircle>
            🎉
          </IconCircle>

          <TextContainer>
            <TitleText>
              A new version is available!
            </TitleText>
            <VersionText>
              Current: {currentVersion} → Latest: {latestVersion}
            </VersionText>
          </TextContainer>
        </ContentWrapper>

        <div style={{display: "flex", gap: "10px", alignItems: "center"}}>
          <AppButton 
            variant="outline" 
            buttonStyle={{width: "100px"}} 
            onClick={dismissPopup}
          >
            Cancel
          </AppButton>
          <AppButton 
            variant="primarydark" 
            buttonStyle={{width: "100px"}} 
            onClick={updateApp}
          >
            Update Now
          </AppButton>
        </div>
      </VersionPopupContainer>

      <AnimationStyles/>
    </AppModel>
  );
};


const AppContent = () => {
  const { userConfig } = useAppSelector(useAuthStore);
  const isAuthenticated = userConfig !== null;
  const { isUpdateAvailable, updateApp,dismissPopup, currentVersion, latestVersion } = useVersionCheck(isAuthenticated);

  return (
    <ThemeProvider theme={theme}>
      <CustomSnackbarProvider>
        <RootNavigator />
        {isAuthenticated && (
          <AnimatedUpdateNotification
            isUpdateAvailable={isUpdateAvailable}
            currentVersion={currentVersion}
            latestVersion={latestVersion}
            updateApp={updateApp}
            dismissPopup={dismissPopup}
          />
        )}
      </CustomSnackbarProvider>
    </ThemeProvider>
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <AppContent />
    </Provider>
  );
};

export default App;